import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerForm from '../../components/CustomerForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

const UpdateCustomerForm = (props) => {
  const { id, contractId, handleModal, onlyBank } = props;
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const query = new URLSearchParams(useLocation().search);

  const [customer, setCustomer] = useState({
    name: '',
    birth_date: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    mobile: '',
    email: ''
  });

  const [ banks, setBanks ] = useState([])

  async function getBanks() {
    showLoader()

    try {
      const response = await api.get(
        `banks?q[active_eq]=true`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.code} - ${value.name}`,
          label: `${value.code} - ${value.name}`
        }
      })

      setBanks(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os bancos.');
    }
  }

  useEffect(() => {
    console.log("ENTRANDO AQUI")
    async function getCustomer() {
      showLoader();
      try {
        const { data } = await api.get(`customers/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(data)
        setCustomer({
          ...customer,
          entity_id: data.entity.id,
          id: data.id,
          mobile: data.mobile,
          rg: data.rg,
          marital_status: data.marital_status,
          gender_customer: data.gender_customer,
          email: data.email,
          birth_date: data.birth_date,
          name: data.entity.name,
          cpf_cnpj: data.entity.cpf_cnpj,
          mother_name: data.mother_name,
          father_name: data.father_name,
          address_id: data.address ? data.address.id : null,
          zip_code: data.address ? data.address.zip_code : null,
          street: data.address ? data.address.street : null,
          number: data.address ? data.address.number : null,
          district: data.address ? data.address.district : null,
          city: data.address ? data.address.city : null,
          state: data.address ? data.address.state : null,
          complement: data.address ? data.address.complement : null,
          bank_account_id: data.bank_account ? data.bank_account.id : null,
          bank_id: data.bank_account ? data.bank_account.id : null,
          bank_name: data.bank_account ? `${data.bank_account.number_bank} - ${data.bank_account.name_bank}` : null,
          agency_account: data.bank_account ? data.bank_account.agency_account : null,
          agency_digit: data.bank_account ? data.bank_account.agency_digit : null,
          account_digit: data.bank_account ? data.bank_account.account_digit : null,
          number_account: data.bank_account ? data.bank_account.number_account : null,
          account_kind: data.bank_account ? data.bank_account.kind : null,
          kind_pix: data.bank_account ? data.bank_account.kind_pix : null,
          kind_account: data.bank_account ? data.bank_account.kind_account : null,
          pix: data.bank_account ? data.bank_account.pix : null,
          month: data.bank_account ? data.bank_account.month : null,
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    getCustomer();
    getBanks()
  }, []);

  const isValidEmail = email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
  const isValidCPF = cpf => /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(
    cpf
  );
  const isValidCPFNumber = cpf => /^\d{11}$/.test(
    cpf
  );
  const isValidPhoneNumber = phone => /^\d{11}$/.test(
    phone
  );
  const isValidPhone = phone => /^\d{11}$/.test(
    phone
  );

  async function onSubmit(data) {
    showLoader();
    console.log(customer)

    if(customer.kind_account == 'pix') {
      if(!customer.kind_pix || customer.kind_pix == 'random'  || customer.kind_pix == '') {
        toastError("Selecione o tipo de chave pix")
        hideLoader();
        return
      }

      if(query.get("kind") && query.get("kind") == 'fgts' && !customer.pix) {
        toastError("Preencher campo pix")
        hideLoader();
        return
      }

      if(!customer.pix){
        toastError("Preencher campo pix")
        hideLoader();
        return
      }

      if(customer.kind_pix == 'email'){
        if(!isValidEmail(customer.pix)){
          toastError("Campo Pix: Formato invalido")
          hideLoader();
          return
        }

        const resultEmailPix = await api.post("/customers/valid_email", JSON.stringify({email: customer.pix}), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        }})

        if(resultEmailPix.data[0] != 1) {
          toastError("Campo Pix: "+resultEmailPix.data[1])
          hideLoader();
          return
        }
      }

      if(customer.kind_pix == 'cpf_cnpj'){
        if(!isValidCPF(customer.pix) && !isValidCPFNumber(customer.pix)){
          toastError("Campo Pix: Formato invalido")
          hideLoader();
          return
        }
      }

      if(customer.kind_pix == 'phone'){
        if(!isValidPhoneNumber(customer.pix) && !isValidPhone(customer.pix)){
          toastError("Campo Pix: Formato invalido")
          hideLoader();
          return
        }
      }
    }else{
      if(!customer.agency_account){
        toastError("Preencher campo Agência")
        hideLoader();
        return
      }

      if(!customer.number_account){
        toastError("Preencher campo Conta")
        hideLoader();
        return
      }

      if(!customer.account_digit){
        toastError("Preencher campo Digito")
        hideLoader();
        return
      }
    }

    const resultEmail = await api.post("/customers/valid_email", JSON.stringify({email: customer.email}), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token()}`,
    }})

    if(resultEmail.data[0] != 1) {
      toastError("Campo Email: "+resultEmail.data[1])
      hideLoader();
      return
    }

    let address_attributes = {
      zip_code: customer.zip_code,
      street: customer.street,
      number: customer.number,
      district: customer.district,
      city: customer.city,
      state: customer.state,
      complement: customer.complement,
    }

    let bank_account_attributes = {}

    if(customer.kind_account == 'pix') {

      bank_account_attributes = {
        kind_pix: customer.kind_pix,
        pix: customer.pix,
        kind_account: customer.kind_account,
        month: customer.month ? customer.month : "",
      }
    }else{
      bank_account_attributes = {
        number_bank: customer.bank_name.split('-')[0],
        name_bank: customer.bank_name.split('-')[1],
        agency_account: customer.agency_account,
        agency_digit: customer.agency_digit,
        number_account: customer.number_account,
        account_digit: customer.account_digit,
        kind: customer.account_kind,
        kind_account: customer.kind_account,
        month: customer.month ? customer.month : "",
      }
    }

    if(customer.address_id){
      address_attributes = {...address_attributes, id: customer.address_id}
    }

    if(customer.bank_account_id){
      bank_account_attributes = {...bank_account_attributes, id: customer.bank_account_id}
    }

    data = {
      customer: {
        birth_date: customer.birth_date,
        email: customer.email,
        mobile: customer.mobile,
        rg: customer.rg,
        gender_customer: customer.gender_customer,
        marital_status: customer.marital_status,
        mother_name: customer.mother_name,
        father_name: customer.father_name,
        entity_attributes: {
          id: customer.entity_id,
          name: customer.name,
          cpf_cnpj: customer.cpf_cnpj,
          address_attributes,
          bank_account_attributes
        }
      }
    }

    try {

      await api.put(`customers/${id}/update_contract?contract_id=${contractId}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Cliente atualizado com sucesso');

      handleModal(false)

      window.location.reload()

    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;
          console.log(message)
          message.forEach((e) => {
            toastError(e);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }

        if (typeof message === 'undefined') {
          message =
            'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';
        }
      }

      toastError(message);
    }
  }

  function handleOnChangeBank(e){
    setCustomer({ ...customer, 'bank_name': e.value })
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    console.log("MUDANDO TUDO", e)
    if (e.target.name == "agency_digit") {
      setCustomer ({ ...customer, ["agency_digit"] : parseDigit(e.target.value) });
    } else if (e.target.name == "account_digit") {
      setCustomer ({ ...customer, ["account_digit"] : parseDigit(e.target.value) });
    } else if (e.target.name == "number_account") {
      setCustomer ({ ...customer, ["number_account"] : parseAccount(e.target.value) });
    } else if (e.target.name == "cpf_cnpj") {
      if ( e.target.value.length < 15 ) {
        let cpf_cnpj = e.target.value
        if(cpf_cnpj.length >= 12) {
          cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
        } else {
          cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
        }
        console.log()
        // setCustomer ({ ...customer,  [e.target.name]: cpf_cnpj});
      }
    } else if (e.target.name == "mobile") {
        if ( e.target.value.length < 16 ) {
          let mobile = e.target.value

          if(mobile.length > 10) {
            mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
          } else {
            mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
          }
          setCustomer ({ ...customer,  [e.target.name]: mobile});
        }
    } else if (e.target.name == 'kind_pix') {
      console.log("Entrando no kind PIX", e.target.value)
      if(e.target.value == 'cpf_cnpj') {
        let cpf_cnpj = customer.cpf_cnpj

        if(cpf_cnpj) {
          cpf_cnpj = cpf_cnpj.replace(/\D/g, '');

          setCustomer({ ...customer, pix: cpf_cnpj, kind_pix: e.target.value });
        } else {
          setCustomer({ ...customer, pix: '', kind_pix: '' })
        }
      } else if (e.target.value == 'email') {
        setCustomer({ ...customer, pix: customer.email, kind_pix: e.target.value });
        //setCustomer({ ...customer, pix: '', kind_pix: e.target.value });
      } else if (e.target.value == 'phone') {
        let mobile = customer.mobile;
        
        if (mobile) {
          mobile = mobile.replace(/\D/g, '');
          setCustomer({ ...customer, pix: mobile, kind_pix: e.target.value })
          // setCustomer({ ...customer, pix: '', kind_pix: e.target.value })
        } else {
          setCustomer({ ...customer, pix: '', kind_pix: '' })
        }
      } else if (e.target.value == 'random') {
        setCustomer({ ...customer, pix: '', kind_pix: '', kind_account: 'ted' })
      } else {
        setCustomer({ ...customer, pix: '', kind_pix: '' })
      }
    }   else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  }

  function dataAddress({ street, district, city, state }) {
    setCustomer({
      ...customer,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  return (
    <>
      <CustomerForm
        hasPending
        onlyBank={!onlyBank}
        banks={banks}
        customer={customer}
        title=""
        titleButton="Salvar"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeBank={handleOnChangeBank}
        dataAddress={dataAddress}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default UpdateCustomerForm;
