import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { CSVLink } from 'react-csv';

import InputMask from 'react-input-mask';
import UserHeader from '../Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';

import { useForm } from 'react-hook-form';

const CommissionProfileAlt = (props) => {
  const {
    commissions,
    commissionEntity,
    entityId,
    hasFgts,
    hasUy3Create,
    handleOnChangeCommissionFgts,
    isCdc
  } = props;

  const { register, handleSubmit, errors } = useForm();
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [exportList, setExportList] = useState([]);

  const [csvDisabled, setCsvDisabled] = useState(false);
  const csvLinkRef = useRef(csvLinkRef);

  const getInstallmentValue = (rate, installments = 12) => {
    return (
      (
        (1000 * (Math.pow(1 + rate / 100, installments) * (rate / 100))) /
        (Math.pow(1 + rate / 100, installments) - 1)
      ).toFixed(2) / 1000
    ).toFixed(5);
  };

  async function export_commission() {
    setCsvDisabled(true);
    try {
      const response = await api.get(
        hasFgts
          ? `users/fgts_export_commissions?${entityId && `id=${entityId}`}&is_cdc=${isCdc ? true : false}`
          : `users/export_commissions?${entityId && `id=${entityId}`}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setExportList(response.data);
      csvLinkRef?.current?.link.click();
    } catch (e) {}
    setCsvDisabled(false);
  }

  async function updateCorban() {
    const data = {
      corban: {
        entity_attributes: {
          id: user.entity_id,
          fgts_commissions_attributes: [
            ...commissions.map((item) => {
              return {
                id: item.id,
                enabled: item.enabled,
              };
            }),
          ],
        },
      },
    };
    try {
      const response = await api.put(
        `/corbans/${user.corban_id}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      toastSuccess('Atualizado com sucesso');
    } catch (e) {
      toastSuccess('Falha ao atualizar');
    }
  }

  function getPercentValue(value, total) {
    if (value == 0 || value == null) {
      return 0;
    }
    if (total == 0 || total == null) {
      return 0;
    }

    return (value * total) / 100;
  }

  return (
    <>
      {props.kind && (
        <>
          <hr className="my-4" />
          <Row>
            <Col lg="10">
              <h6 className="heading-small text-muted mb-4">
                Valor da Comissão
              </h6>
            </Col>
            <Col lg="2">
              <button
                disabled={csvDisabled}
                className="btn btn-primary"
                onClick={export_commission}
              >
                Exportar
              </button>
              <CSVLink
                data={exportList}
                filename={'commissions.csv'}
                separator={';'}
                ref={csvLinkRef}
              ></CSVLink>
            </Col>
          </Row>
          {
            hasUy3Create ? (
              <div className="pl-lg-4">
                <>
                  {/* <h3 className="mb-4">Acima de R$ 250,00</h3>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="basic">
                          Tabela
                        </label>
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="basic">
                          Comissão
                        </label>
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <h3 className="mb-4">R$ 47,50 a R$ 1.999,99</h3>

                  {commissions &&
                    commissions
                      .filter((item) => item.max_value === '1999.99')
                      .map((commission, index) => (
                        commission.enabled && (
                          <CommissionFgtsData
                            hasSecure={false}
                            index={index}
                            commission={commission}
                            register={register}
                            commissionEntity={commissionEntity}
                            getPercentValue={getPercentValue}
                          />
                        )
                      ))}
                    {/* <h3 className="mb-4">R$ 400,00 a R$ 1.999,99</h3>
      
                    {commissions &&
                      commissions
                        .filter((item) => item.max_value === '1999.99')
                        .map((commission, index) => (
                          commission.enabled && (
                            <CommissionFgtsData
                              hasSecure={false}
                              index={index}
                              commission={commission}
                              register={register}
                              commissionEntity={commissionEntity}
                              getPercentValue={getPercentValue}
                            />
                          )
                        ))} */}

                    <h3 className="mb-4">Acima de R$ 2.000,00</h3>
                      
                      {commissions &&
                        commissions
                          .filter((item) => item.min_value === '2000.0')
                          .map((commission, index) => (
                            commission.enabled && (
                              <CommissionFgtsData
                                hasSecure={false}
                                index={index}
                                commission={commission}
                                register={register}
                                commissionEntity={commissionEntity}
                                getPercentValue={getPercentValue}
                              />
                            )
                          ))}    
                </>
                {user.kind === 'corban' && (
                  <div className="text-right">
                    <Button
                      type="button"
                      onClick={updateCorban}
                      className="btn btn-success px-4"
                    >
                      <i className="fas fa-paper-plane" /> Atualizar Dados
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="pl-lg-4">
              <>
                <h3 className="mb-4">Acima de R$ 250,00</h3>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Tabela
                      </label>
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Comissão
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
  
                {/* {!isCdc && <h4 className="mb-4">TABELA SEM SEGURO</h4>}
                {commissions &&
                  commissions
                    .filter((item) => item.tac != 30)
                    .map((commission, index) => (
                      commission.enabled && (
                        <CommissionFgtsData
                          hasSecure={false}
                          index={index}
                          commission={commission}
                          register={register}
                          commissionEntity={commissionEntity}
                          getPercentValue={getPercentValue}
                        />
                      )
                    ))} */}
                {/* {!isCdc && <h4 className="mb-4">TABELA COM SEGURO</h4>} */}
                {commissions &&
                  commissions
                    .filter((item) => !item.lower_than_250)
                    .map((commission, index) => (
                      !commission.is_cdc && (
                        <CommissionFgtsData
                          hasSecure={true}
                          index={index}
                          commission={commission}
                          register={register}
                          commissionEntity={commissionEntity}
                          getPercentValue={getPercentValue}
                        />
                      )
                    ))}
                <h3 className="mb-4">De R$ 100,00 a R$ 249,99</h3>
                {/* {!isCdc && <h4 className="mb-4">TABELA SEM SEGURO</h4>}
                {commissions &&
                  commissions
                    .filter((item) => item.tac == 30)
                    .map((commission, index) => (
                      commission.enabled && (
                        <CommissionFgtsData
                          hasSecure={false}
                          index={index}
                          commission={commission}
                          register={register}
                          commissionEntity={commissionEntity}
                          getPercentValue={getPercentValue}
                        />
                      )
                    ))} */}
                {/* {!isCdc && <h4 className="mb-4">TABELA COM SEGURO</h4>} */}
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Tabela
                      </label>
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Comissão
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                {commissions &&
                  commissions
                    .filter((item) => item.lower_than_250)
                    .map((commission, index) => (
                      !commission.is_cdc && (
                        <CommissionFgtsData
                          hasSecure={true}
                          index={index}
                          commission={commission}
                          register={register}
                          commissionEntity={commissionEntity}
                          getPercentValue={getPercentValue}
                        />
                      )
                    ))}
              </>
              {user.kind === 'corban' && (
                <div className="text-right">
                  <Button
                    type="button"
                    onClick={updateCorban}
                    className="btn btn-success px-4"
                  >
                    <i className="fas fa-paper-plane" /> Atualizar Dados
                  </Button>
                </div>
              )}
            </div>
            )
          }
        </>
      )}
    </>
  );
};

const CommissionFgtsData = ({
  index,
  commission,
  register,
  commissionEntity,
  getPercentValue,
  hasSecure,
}) => {
  return (
    <div key={index}>
      <Row>
        {
          <Col lg="4">
            <FormGroup>
              <Input
                type="text"
                name="basic"
                className="form-control form-control-alternative mt-4"
                id="basic"
                value={commission.name}
                inputRef={register({ required: true })}
                disabled
              />
            </FormGroup>
          </Col>
        }
        {commissionEntity ? (
          <Col lg="2">
            <FormGroup>
              <Input
                type="text"
                name="basic"
                className="form-control form-control-alternative mt-4"
                id="basic"
                mask="99%"
                value={`${
                  commissionEntity.commission_type == 'fix' ? 'R$' : ''
                } ${getPercentValue(
                  hasSecure ? commissionEntity.value : commissionEntity.value,
                  hasSecure
                    ? commission.value_greater_thousand
                    : commission.value
                ).toFixed(3)}${
                  commissionEntity.commission_type == 'percentage' ? '%' : ''
                }`}
                inputRef={register({ required: true })}
                disabled
              />
            </FormGroup>
          </Col>
        ) : hasSecure ? (
          <Col lg="2">
            <FormGroup>
              <Input
                type="text"
                name="basic"
                className="form-control form-control-alternative mt-4"
                id="basic"
                mask="99%"
                value={`${commission.commission_type == 'fix' ? 'R$' : ''} ${
                  commission.value != null
                    ? parseFloat(commission.value).toFixed(2)
                    : 0
                }${commission.commission_type == 'percentage' ? '%' : ''}`}
                inputRef={register({ required: true })}
                disabled
              />
            </FormGroup>
          </Col>
        ) : (
          <Col lg="2">
            <FormGroup>
              <Input
                type="text"
                name="basic"
                className="form-control form-control-alternative mt-4"
                id="basic"
                mask="99%"
                value={`${commission.commission_type == 'fix' ? 'R$' : ''} ${
                  commission.value != null ? commission.value.toFixed(2) : 0
                }${commission.commission_type == 'percentage' ? '%' : ''}`}
                inputRef={register({ required: true })}
                disabled
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CommissionProfileAlt;
