import Index from './views/Index';
import Profile from './views/examples/Profile';
import Tables from './views/examples/Tables';
import Contracts from './pages/Contracts';
import ContractsFgts from './pages/Contracts/fgts';
import Customers from './pages/Customers';
import Store from './pages/Store';
import Establishment from './pages/Establishment';
import Seller from './pages/Seller';
import Users from './pages/Users';
import Corban from './pages/Corban';
import ProfileCommission from './pages/ProfileCommission';
import FgtsProfileCommission from './pages/FgtsProfileCommission';
import CdcProfileCommission from './pages/CdcProfileCommission';
import Subestablishment from './pages/Subestablishment';
import SimulatorConfig from './pages/SimulatorSetting';
import CustomerServices from './pages/CustomerServices';
import CustomerServicesFgts from './pages/CustomerServices/fgts';
import BasicInterests from './pages/BasicInterest';
import FgtsBasicInterests from './pages/FgtsBasicInterest';
import StoresPending from 'pages/StorePending';
import StoreCommission from 'pages/StoreCommission';
import Signer from 'pages/Signer';
import GatewaysSetting from 'pages/GatewaySetting';
import Ranking from 'pages/Ranking';
import RankingFgts from 'pages/RankingFgts';
import CreateCustomerService from 'pages/CreateCustomerService';
import CreateCustomerServiceFgts from 'pages/CreateCustomerService/fgts';
import Referrers from 'pages/Referrers';
import ImportContract from 'pages/ImportContract';
import UpdateCommission from 'pages/UpdateCommission';
import UpdateSecure from 'pages/UpdateSecure';
import CorbanTransfer from 'pages/Transfers';

const routes = [];

const customer_services_fgts = {
  path: '/fgts-customer-services',
  name: 'Atendimentos Fgts',
  icon: 'fas fa-id-badge text-primary',
  component: CustomerServicesFgts,
  layout: '/admin',
};

const customer_services = {
  path: '/customer-services',
  name: 'Atendimentos Cartão',
  icon: 'fas fa-id-badge text-primary',
  component: CustomerServices,
  layout: '/admin',
};

const contracts = {
  path: '/contracts',
  name: 'Contratos Cartão',
  icon: 'fas fa-file-contract text-success',
  component: Contracts,
  layout: '/admin',
};

const update_commission = {
  path: '/update_commission',
  name: 'Atualizar comissão',
  icon: 'fas fa-file-contract text-success',
  component: UpdateCommission,
  layout: '/admin',
};

const contracts_fgts = {
  path: '/fgts-contracts',
  name: ' Cartão',
  icon: 'fas fa-file-contract text-success',
  component: ContractsFgts,
  layout: '/admin',
};

const customers = {
  path: '/customers',
  name: 'Clientes',
  icon: 'ni ni-bullet-list-67 text-red',
  component: Customers,
  layout: '/admin',
};

const profile = {
  path: '/profile',
  name: 'Perfil',
  icon: 'ni ni-single-02 text-yellow',
  component: Profile,
  layout: '/admin',
};

const referrers = {
  path: '/referrers',
  name: 'Indicadores',
  icon: 'ni ni-bullet-list-67 text-primary',
  component: Referrers,
  layout: '/admin',
};

const new_customer_service = {
  path: '/customer-services/create',
  name: '+Nova Proposta Cartão',
  icon: 'fas fa-id-badge text-primary',
  component: CreateCustomerService,
  layout: '/admin',
};

const new_customer_service_fgts = {
  path: '/fgts-customer-services/create',
  name: '+Nova Proposta FGTS',
  icon: 'fas fa-id-badge text-primary',
  component: CreateCustomerServiceFgts,
  layout: '/admin',
};

const commission_link = {
  path: '/commission',
  name: 'Dados de Comissão Cartão',
  icon: 'fas fa-cog text-primary',
  component: ProfileCommission,
  layout: '/admin',
};

const fgts_commission_link = {
  path: '/fgts_commission',
  name: 'Dados de Comissão FGTS',
  icon: 'fas fa-cog text-primary',
  component: FgtsProfileCommission,
  layout: '/admin',
};

const cdc_commission_link = {
  path: '/cdc_commission',
  name: 'Dados de Comissão CDC',
  icon: 'fas fa-cog text-primary',
  component: CdcProfileCommission,
  layout: '/admin',
};

const store_commission_link = {
  path: '/stores/commissions',
  name: 'Comissão das lojas',
  icon: 'fas fa-cog text-primary',
  component: StoreCommission,
  layout: '/admin',
};

const simulator_link = {
  path: '/simulator-settings',
  name: 'Configurações do simulador',
  icon: 'fas fa-cog text-primary',
  component: SimulatorConfig,
  layout: '/admin',
};

const user_link = {
  path: '/users',
  name: 'Usuários',
  icon: 'fas fa-users text-success',
  component: Users,
  layout: '/admin',
};

const basic_interest_link = {
  path: '/basic-interests',
  name: 'Juros Básicos',
  icon: 'fas fa-file-contract text-success',
  component: BasicInterests,
  layout: '/admin',
};

const fgts_basic_interest_link = {
  path: '/fgts-basic-interests',
  name: 'Juros Básicos Fgts',
  icon: 'fas fa-file-contract text-success',
  component: FgtsBasicInterests,
  layout: '/admin',
};

const corban_link = {
  path: '/corbans',
  name: 'Correspondentes Bancários',
  icon: 'fas fa-users text-success',
  component: Corban,
  layout: '/admin',
};

const subestablishment_link = {
  path: '/subestablishments',
  name: 'Subestabelecimentos',
  icon: 'fas fa-users text-success',
  component: Subestablishment,
  layout: '/admin',
};

const establishment_link = {
  path: '/establishments',
  name: 'Estabelecimentos',
  icon: 'fas fa-users text-success',
  component: Establishment,
  layout: '/admin',
};

const store_link = {
  path: '/stores',
  name: 'Lojas',
  icon: 'fas fa-users text-success',
  component: Store,
  layout: '/admin',
};

const secure_link = {
  path: '/update_secure',
  name: 'Configuração seguro',
  icon: 'fas fa-users text-success',
  component: UpdateSecure,
  layout: '/admin',
};

const store_pending_link = {
  path: '/stores/pendings',
  name: 'Lojas Pendentes',
  icon: 'fas fa-users text-success',
  component: StoresPending,
  layout: '/admin',
};

const seller_link = {
  path: '/sellers',
  name: 'Vendedores',
  icon: 'fas fa-users text-success',
  component: Seller,
  layout: '/admin',
};

const signer_link = {
  path: '/signers',
  name: 'Signatários',
  icon: 'fas fa-users text-success',
  component: Signer,
  layout: '/admin',
};

const gateway_link = {
  path: '/gateways',
  name: 'Gateway de Pagamento',
  icon: 'fas fa-cog text-success',
  component: GatewaysSetting,
  layout: '/admin',
};

const ranking_link = {
  path: '/rankings',
  name: 'Ranking de Produção',
  icon: 'fas fa-cog text-success',
  component: Ranking,
  layout: '/admin',
};

const ranking_fgts_link = {
  path: '/rankings_fgts',
  name: 'Ranking de Produção Fgts',
  icon: 'fas fa-cog text-success',
  component: RankingFgts,
  layout: '/admin',
};

const import_contracts_link = {
  path: '/import_contracts',
  name: 'Importar Tabela Contratos',
  icon: 'fas fa-cog text-success',
  component: ImportContract,
  layout: '/admin',
};

const corban_transfer_link = {
  path: '/transfers',
  name: 'Transferencias',
  icon: 'fas fa-cog text-success',
  component: CorbanTransfer,
  layout: '/admin',
};

export const getRoutes = () => {
  let newRoutes = [];
  const user = JSON.parse(localStorage.getItem('@UGate:user'));
  if (user) {
    newRoutes = [...routes];
    if (user.kind == 'master') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(new_customer_service) == -1) {
        newRoutes.unshift(new_customer_service);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      if (newRoutes.indexOf(basic_interest_link) == -1) {
        newRoutes.push(basic_interest_link);
      }
      if (newRoutes.indexOf(fgts_basic_interest_link) == -1) {
        newRoutes.push(fgts_basic_interest_link);
      }
      if (newRoutes.indexOf(simulator_link) == -1) {
        newRoutes.push(simulator_link);
      }
      if (newRoutes.indexOf(referrers) == -1) {
        newRoutes.push(referrers);
      }
      if (newRoutes.indexOf(update_commission) == -1) {
        newRoutes.push(update_commission);
      }
      if (newRoutes.indexOf(secure_link) == -1) {
        newRoutes.push(secure_link);
      }
      if (newRoutes.indexOf(corban_link) == -1) {
        newRoutes.push(corban_link);
      }
      if (newRoutes.indexOf(subestablishment_link) == -1) {
        newRoutes.push(subestablishment_link);
      }
      if (newRoutes.indexOf(establishment_link) == -1) {
        newRoutes.push(establishment_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(signer_link) == -1) {
        newRoutes.push(signer_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
      if (newRoutes.indexOf(gateway_link) == -1) {
        newRoutes.push(gateway_link);
      }
      if (newRoutes.indexOf(import_contracts_link) == -1) {
        newRoutes.push(import_contracts_link);
      }
    } else if (user.kind == 'operational') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(new_customer_service) == -1) {
        newRoutes.unshift(new_customer_service);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      if (newRoutes.indexOf(referrers) == -1) {
        newRoutes.push(referrers);
      }
      if (newRoutes.indexOf(update_commission) == -1) {
        newRoutes.push(update_commission);
      }
      if (newRoutes.indexOf(corban_link) == -1) {
        newRoutes.push(corban_link);
      }
      if (newRoutes.indexOf(subestablishment_link) == -1) {
        newRoutes.push(subestablishment_link);
      }
      if (newRoutes.indexOf(establishment_link) == -1) {
        newRoutes.push(establishment_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
      if (newRoutes.indexOf(import_contracts_link) == -1) {
        newRoutes.push(import_contracts_link);
      }
    } else if (user.kind == 'superintendent') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(corban_link) == -1) {
        newRoutes.push(corban_link);
      }
      if (newRoutes.indexOf(subestablishment_link) == -1) {
        newRoutes.push(subestablishment_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
    } else if (user.kind == 'regional_manager') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      
      if (newRoutes.indexOf(corban_link) == -1) {
        newRoutes.push(corban_link);
      }
      if (newRoutes.indexOf(subestablishment_link) == -1) {
        newRoutes.push(subestablishment_link);
      }
      if (newRoutes.indexOf(establishment_link) == -1) {
        newRoutes.push(establishment_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
    } else if (user.kind == 'commercial_manager') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);

      if (newRoutes.indexOf(new_customer_service) == -1) {
        newRoutes.unshift(new_customer_service);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      // if (newRoutes.indexOf(store_commission_link) == -1) {
      //   newRoutes.push(store_commission_link);
      // }
      // if (newRoutes.indexOf(establishment_link) == -1) {
      //   newRoutes.push(establishment_link);
      // }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      // if (newRoutes.indexOf(user_link) == -1) {
      //   newRoutes.push(user_link);
      // }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
    } else if (user.kind == 'commercial_manager_internal') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(subestablishment_link) == -1) {
        newRoutes.push(subestablishment_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
    } else if (user.kind == 'supervisor_commmercial_internal') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(store_commission_link) == -1) {
        newRoutes.push(store_commission_link);
      }
      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
      if (newRoutes.indexOf(ranking_link) == -1) {
        newRoutes.push(ranking_link);
      }
      if (newRoutes.indexOf(ranking_fgts_link) == -1) {
        newRoutes.push(ranking_fgts_link);
      }
    } else if (user.kind == 'intern_consultant') {
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

      if (newRoutes.indexOf(store_link) == -1) {
        newRoutes.push(store_link);
      }
      if (newRoutes.indexOf(seller_link) == -1) {
        newRoutes.push(seller_link);
      }
      if (newRoutes.indexOf(user_link) == -1) {
        newRoutes.push(user_link);
      }
    } else if (user.kind == 'corban') {
      if (user.role_default == 'user_corban_register') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(subestablishment_link) == -1)
          newRoutes.push(subestablishment_link);
        if (newRoutes.indexOf(store_link) == -1) newRoutes.push(store_link);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
      } else if (user.role_default == 'user_corban_financial') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(commission_link) == -1)
          newRoutes.push(commission_link);
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          if (user.has_fgts) {
            newRoutes.push(fgts_commission_link);
          }
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          if (user.has_cdc) {
            newRoutes.push(cdc_commission_link);
          }
        }
        if (newRoutes.indexOf(ranking_link) == -1) newRoutes.push(ranking_link);
        if (newRoutes.indexOf(ranking_fgts_link) == -1)
          newRoutes.push(ranking_fgts_link);
      } else if (user.role_default == 'master_corban') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(subestablishment_link) == -1) {
          newRoutes.push(subestablishment_link);
        }
        if (newRoutes.indexOf(establishment_link) == -1) {
          newRoutes.push(establishment_link);
        }

        if (newRoutes.indexOf(store_link) == -1) {
          newRoutes.push(store_link);
        }
        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }

        if (newRoutes.indexOf(new_customer_service) == -1) {
          newRoutes.unshift(new_customer_service);
        }
        if (newRoutes.indexOf(corban_transfer_link) == -1) {
          newRoutes.unshift(corban_transfer_link);
        }
        if (newRoutes.indexOf(user_link) == -1) {
          newRoutes.push(user_link);
        }
        if (newRoutes.indexOf(commission_link) == -1) {
          newRoutes.unshift(commission_link);
        }
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          newRoutes.unshift(fgts_commission_link);
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          newRoutes.unshift(cdc_commission_link);
        }
        if (newRoutes.indexOf(ranking_link) == -1) {
          newRoutes.push(ranking_link);
        }
        if (newRoutes.indexOf(ranking_fgts_link) == -1) {
          newRoutes.push(ranking_fgts_link);
        }
      } else if (user.role_default == 'user_corban') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(subestablishment_link) == -1) {
          newRoutes.push(subestablishment_link);
        }
        if (newRoutes.indexOf(store_link) == -1) {
          newRoutes.push(store_link);
        }
        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }
      } else {
        if (newRoutes.indexOf(customer_services) == -1)
          newRoutes.push(customer_services);
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(subestablishment_link) == -1)
          newRoutes.push(subestablishment_link);
        if (newRoutes.indexOf(store_link) == -1) newRoutes.push(store_link);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
        if (newRoutes.indexOf(user_link) == -1) newRoutes.push(user_link);
        if (newRoutes.indexOf(commission_link) == -1)
          newRoutes.push(commission_link);
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          if (user.has_fgts) {
            newRoutes.push(fgts_commission_link);
          }
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          if (user.has_cdc) {
            newRoutes.push(cdc_commission_link);
          }
        }
        if (newRoutes.indexOf(ranking_link) == -1) newRoutes.push(ranking_link);
        if (newRoutes.indexOf(ranking_fgts_link) == -1)
          newRoutes.push(ranking_fgts_link);
      }
    } else if (user.kind == 'subestablishment') {
      if (user.role_default == 'user_subest_register') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(store_link) == -1) newRoutes.push(store_link);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
      } else if (user.role_default == 'user_subest_financial') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(commission_link) == -1)
          newRoutes.push(commission_link);
        if (newRoutes.indexOf(ranking_link) == -1) newRoutes.push(ranking_link);
        if (newRoutes.indexOf(ranking_fgts_link) == -1)
          newRoutes.push(ranking_fgts_link);
      } else if (user.role_default == 'master_subest') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(store_link) == -1) {
          newRoutes.push(store_link);
        }
        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }

        if (newRoutes.indexOf(new_customer_service) == -1) {
          newRoutes.unshift(new_customer_service);
        }
        if (newRoutes.indexOf(user_link) == -1) {
          newRoutes.push(user_link);
        }
        if (newRoutes.indexOf(commission_link) == -1) {
          newRoutes.unshift(commission_link);
        }
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          newRoutes.unshift(fgts_commission_link);
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          newRoutes.unshift(cdc_commission_link);
        }
        if (newRoutes.indexOf(ranking_link) == -1) {
          newRoutes.push(ranking_link);
        }
        if (newRoutes.indexOf(ranking_fgts_link) == -1) {
          newRoutes.push(ranking_fgts_link);
        }
      } else if (user.role_default == 'user_subest') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(store_link) == -1) {
          newRoutes.push(store_link);
        }
        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }
      } else {
        if (newRoutes.indexOf(customer_services) == -1)
          newRoutes.push(customer_services);
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(store_link) == -1) newRoutes.push(store_link);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
        if (newRoutes.indexOf(user_link) == -1) newRoutes.push(user_link);
        if (newRoutes.indexOf(commission_link) == -1)
          newRoutes.push(commission_link);
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          if (user.has_fgts) {
            newRoutes.push(fgts_commission_link);
          }
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          if (user.has_cdc) {
            newRoutes.push(cdc_commission_link);
          }
        }
        if (newRoutes.indexOf(ranking_link) == -1) newRoutes.push(ranking_link);
        if (newRoutes.indexOf(ranking_fgts_link) == -1)
          newRoutes.push(ranking_fgts_link);
      }
    } else if (user.kind == 'store') {
      if (user.role_default == 'user_store_register') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
      } else if (user.role_default == 'user_store_financial') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(commission_link) == -1)
          newRoutes.push(commission_link);
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          if (user.has_fgts) {
            newRoutes.push(fgts_commission_link);
          }
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          if (user.has_cdc) {
            newRoutes.push(cdc_commission_link);
          }
        }
        if (newRoutes.indexOf(ranking_link) == -1) newRoutes.push(ranking_link);
        if (newRoutes.indexOf(ranking_fgts_link) == -1)
          newRoutes.push(ranking_fgts_link);
      } else if (user.role_default == 'master_store') {
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }

        if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);

        if (newRoutes.indexOf(new_customer_service) == -1) {
          newRoutes.unshift(new_customer_service);
        }

        if (!user.has_cdc) {
          if (newRoutes.indexOf(user_link) == -1) {
            newRoutes.push(user_link);
          }
          if (newRoutes.indexOf(commission_link) == -1) {
            newRoutes.unshift(commission_link);
          }
        }
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          newRoutes.unshift(fgts_commission_link);
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          newRoutes.unshift(cdc_commission_link);
        }
        if (!user.has_cdc) {
          if (newRoutes.indexOf(ranking_link) == -1) {
            newRoutes.push(ranking_link);
          }
          if (newRoutes.indexOf(ranking_fgts_link) == -1) {
            newRoutes.push(ranking_fgts_link);
          }
        }
      } else if (user.role_default == 'user_store') {
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);

        if (newRoutes.indexOf(seller_link) == -1) {
          newRoutes.push(seller_link);
        }
      } else {
        if (newRoutes.indexOf(new_customer_service) == -1) {
          newRoutes.unshift(new_customer_service);
        }
        if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
          newRoutes.unshift(new_customer_service_fgts);
        }
        if (newRoutes.indexOf(customer_services) == -1)
          newRoutes.push(customer_services);
        if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
        if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
        if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
        if (newRoutes.indexOf(seller_link) == -1) newRoutes.push(seller_link);
        if (!user.has_cdc) {
          if (newRoutes.indexOf(user_link) == -1) newRoutes.push(user_link);
          if (newRoutes.indexOf(commission_link) == -1)
            newRoutes.push(commission_link);
        }
        if (newRoutes.indexOf(fgts_commission_link) == -1) {
          if (user.has_fgts) {
            newRoutes.push(fgts_commission_link);
          }
        }
        if (newRoutes.indexOf(cdc_commission_link) == -1) {
          if (user.has_cdc) {
            newRoutes.push(cdc_commission_link);
          }
        }
        if (!user.has_cdc) {
          if (newRoutes.indexOf(ranking_link) == -1)
            newRoutes.push(ranking_link);
          if (newRoutes.indexOf(ranking_fgts_link) == -1)
            newRoutes.push(ranking_fgts_link);
        }
      }
    } else if (user.kind == 'seller') {
      if (newRoutes.indexOf(new_customer_service) == -1) {
        newRoutes.unshift(new_customer_service);
      }
      if (newRoutes.indexOf(new_customer_service_fgts) == -1) {
        newRoutes.unshift(new_customer_service_fgts);
      }
      if (newRoutes.indexOf(customer_services) == -1)
        newRoutes.push(customer_services);
      if (newRoutes.indexOf(contracts) == -1) newRoutes.push(contracts);
      if (newRoutes.indexOf(customers) == -1) newRoutes.push(customers);
      if (newRoutes.indexOf(profile) == -1) newRoutes.push(profile);
      if (newRoutes.indexOf(commission_link) == -1) {
        newRoutes.push(commission_link);
      }
      if (newRoutes.indexOf(fgts_commission_link) == -1) {
        if (user.has_fgts) {
          newRoutes.push(fgts_commission_link);
        }
      }
      if (newRoutes.indexOf(cdc_commission_link) == -1) {
        if (user.has_cdc) {
          newRoutes.push(cdc_commission_link);
        }
      }
    }
  }

  return newRoutes;
};
