import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import LogTable from '../../components/LogTable';
import LogSubVersions from '../../components/LogTable/subVersions';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { States } from '../../variables/assistants';
import Search from '../../components/Search';

const LIMIT = 10;

const CorbanTransfer = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { id } = useParams();
  const [transfers, setTransfers] = useState([]);
  const [transferModal, setTransferModal] = useState(false);
  const [transferValue, setTransferValue] = useState("");
  const [balanceValue, setBalanceValue] = useState("");
  const [contract, setContract] = useState("");

  async function getTransfers() {
    showLoader();

    try {
      const response = await api.get(
        `/accounts/1/transfers_pending`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setTransfers(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista as transferencias');
    }
  }

  const handleToggleTransfer = () => {
    setTransferModal(!transferModal)
  }


  useEffect(() => {
    getTransfers();
  }, []);


  return (
    <>
      <div className={`header bg-gradient-info pb-8 pt-5 pt-md-8`}>
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Transferências</h3>
                    </Col>
                    {
                      contract.status_original == "contract_signed" && (
                        <Col lg="5" className="mt-3 mt-lg-0">
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <Button color="primary" onClick={() => handleToggleTransfer()}>
                              Nova Transferência
                            </Button>
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">-</th>
                      <th scope="col">Código</th>
                      <th scope="col">Status</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Data de Criação</th>
                      <th scope="col">Data da Transferencia</th>
                      <th scope="col">Mensagem</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfers.map((transfer) => (
                      <tr key={transfer.id}>
                        <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                        </UncontrolledDropdown>
                        </td>
                        <td>{transfer.code_transaction}</td>
                        <td>{transfer.status_i18n}</td>
                        <td>{transfer.value}</td>
                        <td>{transfer.created_at}</td>
                        <td>{transfer.payment_date}</td>
                        <td>{transfer.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default CorbanTransfer;
