import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { controllers } from 'chart.js';
import Select from 'react-select';
import Subestablishment from 'pages/Subestablishment';
import CurrencyInput from 'react-currency-input';
import { Tooltip } from 'bootstrap';
import { VisibilityByUserRoles } from 'util/visibility';
import api from '../../services/api';
import token from '../../util/setAuthToken';

const StoreForm = ({
  store,
  titleButton,
  fgtsCommissions,
  handleOnChangeCommissionFgts,
  contactFields,
  banks,
  isQista,
  managers,
  corbans,
  subs,
  kind,
  hasEdit,
  title,
  onSubmit,
  handleOnChange,
  handleOnChangeContact,
  handleClickContact,
  handleClickContactRemove,
  handleOnChangeTypeContact,
  handleOnChangeBank,
  dataAddress,
  controller,
  insertPercentage,
  insertMoney,
  handleOnChangeValueFix,
  handleOnAccountDigits,
  handleOnAgencyDigits,
  handleOnChangeCorban,
  handleOnChangeSub,
  register,
  handleSubmit,
  errors,
  corbanName,
  handleClickItemListFilter,
}) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [addressDisabled, setAddressDisabled] = useState(true);
  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          dataAddress({
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
          setAddressDisabled(true);
        } else {
          dataAddress({
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
          setAddressDisabled(false);
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  async function handleChangeEmail(e) {
    const { value } = e.target;
    handleOnChange(e);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      const { data } = await api.get(
        `contracts/check_email_exist?email=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'E-mail já consta no sistema') {
        return toastError('E-mail já consta no sistema');
      }
    }
  }

  async function handleChangeCpf(e) {
    const { value } = e.target;
    handleOnChange(e);
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (cpfRegex.test(value)) {
      const cpf = value.replace(/[^0-9]+/g, '');
      const { data } = await api.get(
        `contracts/check_cpf_exist?cpf_cnpj=${cpf}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'CPF já consta no sistema') {
        return toastError('CPF já consta no sistema');
      }
    }
  }

  async function handleChangeCnpj(e) {
    const { value } = e.target;
    handleOnChange(e);
    const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    if (cnpjRegex.test(value)) {
      const cnpj = value.replace(/[^0-9]+/g, '');
      const { data } = await api.get(
        `contracts/check_cnpj_exist?cpf_cnpj=${cnpj}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'CNPJ já consta no sistema') {
        return toastError('CNPJ já consta no sistema');
      }
    }
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações {isQista ? 'do Estabelecimento' : 'da Loja'}
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <VisibilityByUserRoles roles={['master', 'operational']}>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="social_reason"
                          >
                            Selecione a corban
                          </label>
                          <FormGroup>
                            <Input
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              // placeholder="Selecione o corban"
                              value={corbanName}
                              onChange={handleOnChangeCorban}
                            />
                            <ul className="corban-list">
                              {corbans &&
                                corbans.map((item) => {
                                  return (
                                    <li
                                      className="item-list"
                                      onClick={() =>
                                        handleClickItemListFilter(item.value)
                                      }
                                    >
                                      {item.value}
                                    </li>
                                  );
                                })}
                            </ul>
                          </FormGroup>
                        </Col>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'master_corban',
                          'user_corban_register',
                        ]}
                      >
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="social_reason"
                          >
                            Selecione o Subestabelecimento
                          </label>
                          <FormGroup>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              placeholder="Selecione o Subestabelecimento"
                              value={{
                                value: store.sub_id,
                                label: store.sub_id,
                              }}
                              options={subs}
                              onChange={handleOnChangeSub}
                            />
                          </FormGroup>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>
                    {!isQista ? (
                      <>
                        <VisibilityByUserRoles
                          roles={['master', 'operational']}
                        >
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="auto"
                                >
                                  Auto Contratação?
                                </label>
                                <Input
                                  type="select"
                                  id="auto"
                                  name="auto"
                                  value={store && store.auto}
                                  onChange={handleOnChange}
                                >
                                  <option>Selecione o tipo</option>
                                  <option value="true">Sim</option>
                                  <option value="false">Não</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="regional_manager_id"
                                >
                                  Gerente Regional
                                </label>
                                <Input
                                  type="select"
                                  id="regional_manager_id"
                                  name="regional_manager_id"
                                  value={store && store.regional_manager_id}
                                  onChange={handleOnChange}
                                >
                                  <option>Selecione o tipo</option>
                                  {managers &&
                                    managers.map((manager, index) => (
                                      <option value={manager.id}>
                                        {manager.name}
                                      </option>
                                    ))}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </VisibilityByUserRoles>
                        <Row>
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'master_store',
                              'user_store_register',
                              'user_store',
                            ]}
                          >
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="internal_code"
                                >
                                  Código interno
                                </label>
                                <input
                                  name="internal_code"
                                  className="form-control form-control-alternative"
                                  id="internal_code"
                                  type="number"
                                  value={store && store.internal_code}
                                  ref={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.internal_code && (
                                  <small className="text-danger">
                                    Código interno é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </VisibilityByUserRoles>
                        </Row>
                        <Row>
                          <Col lg="6 mb-4">
                            <label
                              className="form-control-label"
                              htmlFor="store_kind"
                            >
                              Tipo de Loja
                            </label>
                            <Input
                              type="select"
                              id="store_kind"
                              name="store_kind"
                              value={store && store.store_kind}
                              onChange={handleOnChange}
                            >
                              <option value="0">Pessoa Jurídica</option>
                              <option value="1">Pessoa Física</option>
                            </Input>
                          </Col>
                          <Col lg="6 mb-4">
                            <label
                              className="form-control-label"
                              htmlFor="attendance_method"
                            >
                              Principal forma de atendimento
                            </label>
                            <Input
                              type="select"
                              id="attendance_method"
                              name="attendance_method"
                              value={store && store.attendance_method}
                              onChange={handleOnChange}
                            >
                              <option value="fisical">
                                Presencial (loja física)
                              </option>
                              <option value="digital">
                                Online (marketing digital)
                              </option>
                              <option value="call_center">
                                Telefônico (call center)
                              </option>
                            </Input>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <VisibilityByUserRoles
                          roles={[
                            'master',
                            'operational',
                            'master_store',
                            'user_store_register',
                            'user_store',
                          ]}
                        >
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="code_qista"
                              >
                                Código Qista
                              </label>
                              <input
                                name="code_qista"
                                className="form-control form-control-alternative"
                                id="code_qista"
                                type="number"
                                value={store && store.code_qista}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.code_qista && (
                                <small className="text-danger">
                                  Código qista é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </VisibilityByUserRoles>
                      </Row>
                    )}

                    <Row>
                      {store.store_kind == 0 && (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="social_reason"
                            >
                              Razão social
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-alternative"
                              id="social_reason"
                              name="social_reason"
                              placeholder="Razão Social"
                              value={store && store.social_reason}
                              ref={register({ required: true })}
                              onChange={handleOnChange}
                            />

                            {errors.social_reason && (
                              <small className="text-danger">
                                A razão social é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            {isQista
                              ? 'Nome Fantasia'
                              : store.store_kind == 0
                              ? 'Nome Fantasia - Cidade/UF'
                              : 'Nome - Cidade/UF '}
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="name"
                            name="name"
                            placeholder={
                              isQista
                                ? 'Nome Fantasia'
                                : store.store_kind == 0
                                ? 'Nome Fantasia - Cidade/UF'
                                : 'Nome - Cidade/UF'
                            }
                            value={store && store.name}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome fantasia é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            {store.store_kind == 0 ? 'CNPJ' : 'CPF'}
                          </label>
                          <input
                            type="text"
                            name="cpf_cnpj"
                            placeholder={
                              store.store_kind == 0
                                ? '99.999.999/9999-99'
                                : '999.999.999-99'
                            }
                            className="form-control form-control-alternative"
                            id="cpf_cnpj"
                            value={store && store.cpf_cnpj}
                            ref={register({ required: true })}
                            onChange={handleChangeCnpj}
                          />
                          {errors.cpf_cnpj && (
                            <small className="text-danger">
                              CNPJ é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {controller == 'new' ? (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Representante legal
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_cpf"
                              >
                                CPF
                              </label>
                              <input
                                type="text"
                                name="user_cpf"
                                placeholder="999.999.999-99"
                                className="form-control form-control-alternative"
                                id="user_cpf"
                                value={store && store.user_cpf}
                                ref={register({ required: true })}
                                onChange={handleChangeCpf}
                              />
                              {errors.user_cpf && (
                                <small className="text-danger">
                                  CPF é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_name"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                name="user_name"
                                className="form-control form-control-alternative"
                                id="user_name"
                                value={store && store.user_name}
                                placeholder="Nome Completo"
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                maxLength={35}
                              />
                              {errors.user_name && (
                                <small className="text-danger">
                                  O nome é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_email"
                              >
                                Email do representante (Login de acesso da loja)
                              </label>
                              <input
                                type="user_email"
                                name="user_email"
                                className="form-control form-control-alternative"
                                id="user_email"
                                value={store && store.user_email}
                                placeholder="example@example.com"
                                ref={register({ required: true })}
                                onChange={handleChangeEmail}
                              />
                              {errors.user_email && (
                                <small className="text-danger">
                                  Email é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_mobile"
                              >
                                Celular
                              </label>
                              <input
                                type="text"
                                name="user_mobile"
                                className="form-control form-control-alternative"
                                id="user_mobile"
                                placeholder="(99) 99999-9999"
                                value={store && store.user_mobile}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.user_mobile && (
                                <small className="text-danger">
                                  O celular é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Senha
                              </label>
                              <InputMask
                                type="password"
                                name="password"
                                placeholder="Senha"
                                className="form-control form-control-alternative"
                                id="password"
                                value={store && store.password}
                                inputRef={register({
                                  required: hasEdit ? false : true,
                                })}
                                onChange={handleOnChange}
                              />
                              {errors.password && (
                                <small className="text-danger">
                                  A senha é obrigatorio
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Representante legal
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_cpf"
                              >
                                CPF
                              </label>
                              <InputMask
                                type="text"
                                name="user_cpf"
                                className="form-control form-control-alternative"
                                id="user_cpf"
                                value={store && store.user_cpf}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_name"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                name="user_name"
                                className="form-control form-control-alternative"
                                id="user_name"
                                value={store && store.user_name}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_email"
                              >
                                Email do representante (Login de acesso da loja)
                              </label>
                              <input
                                type="user_email"
                                name="user_email"
                                className="form-control form-control-alternative"
                                id="user_email"
                                value={store && store.user_email}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_mobile"
                              >
                                Celular
                              </label>
                              <InputMask
                                type="text"
                                name="user_mobile"
                                className="form-control form-control-alternative"
                                id="user_mobile"
                                value={store && store.user_mobile}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Senha
                              </label>
                              <InputMask
                                type="password"
                                name="password"
                                placeholder="Senha"
                                className="form-control form-control-alternative"
                                id="password"
                                value={store && store.password}
                                onChange={handleOnChange}
                              />
                              {errors.password && (
                                <small className="text-danger">
                                  A senha é obrigatorio
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço da Loja
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zip_code"
                          >
                            CEP
                          </label>
                          <InputMask
                            name="zip_code"
                            className="form-control form-control-alternative"
                            id="zip_code"
                            placeholder="CEP"
                            type="text"
                            mask="99999-999"
                            maskChar=""
                            value={store && store.zip_code}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            onBlur={handleBlurZipCode}
                          />
                          {errors.zip_code && (
                            <small className="text-danger">
                              O cep é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <input
                            name="street"
                            className="form-control form-control-alternative"
                            id="street"
                            placeholder="Endereço"
                            type="text"
                            value={store && store.street}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.street && (
                            <small className="text-danger">
                              O endereço é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <input
                            name="number"
                            className="form-control form-control-alternative"
                            id="number"
                            type="number"
                            value={store && store.number}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.number && (
                            <small className="text-danger">
                              O número é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <input
                            name="district"
                            className="form-control form-control-alternative"
                            id="district"
                            placeholder="Bairro"
                            type="text"
                            value={store && store.district}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.district && (
                            <small className="text-danger">
                              O bairro é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <input
                            name="city"
                            className="form-control form-control-alternative"
                            id="city"
                            placeholder="Cidade"
                            type="text"
                            value={store && store.city}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.city && (
                            <small className="text-danger">
                              A cidade é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <input
                            name="state"
                            className="form-control form-control-alternative"
                            id="state"
                            placeholder="Estado"
                            type="text"
                            value={store && store.state}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.state && (
                            <small className="text-danger">
                              O estado é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <input
                            name="complement"
                            className="form-control form-control-alternative"
                            id="complement"
                            placeholder="Complemento"
                            type="text"
                            value={store && store.complement}
                            ref={register({ required: false })}
                            onChange={handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg={'2 mb-4'}>
                        <label
                          className="form-control-label"
                          htmlFor="kind_pix"
                        >
                          Tipo Pix
                        </label>
                        <Input
                          type="select"
                          id="kind_pix"
                          name="kind_pix"
                          value={store && store.kind_pix}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="cpf_cnpj">CPF/CNPJ</option>
                          <option value="email">E-mail</option>
                          <option value="phone">Telefone</option>
                          <option value="random">
                            {'Dados Bancários (conforme abaixo)'}
                          </option>
                        </Input>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="pix">
                            Pix
                          </label>
                          <InputMask
                            type="text"
                            name="pix"
                            className="form-control form-control-alternative"
                            id="pix"
                            placeholder="Pix"
                            value={store && store.pix}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.pix && (
                            <small className="text-danger">
                              Pix é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="agency_number"
                        >
                          Banco
                        </label>
                        <FormGroup>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            value={{
                              value: store.bank_name,
                              label: store.bank_name,
                            }}
                            options={banks}
                            onChange={handleOnChangeBank}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Agência
                          </label>
                          <InputMask
                            type="text"
                            name="agency_account"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9999"
                            mask="9999"
                            maskChar=""
                            value={store && store.agency_account}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.agency_account && (
                            <small className="text-danger">
                              A agência é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="agency_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9"
                            mask="*"
                            maskChar=""
                            value={store && store.agency_digit}
                            inputRef={register({ required: false })}
                            onChange={handleOnChange}
                          />
                        </FormGroup> */}
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <InputMask
                            type="text"
                            name="number_account"
                            className="form-control form-control-alternative"
                            id="number_account"
                            placeholder="9999999"
                            // mask="9999999"
                            // maskChar=""
                            value={store && store.number_account}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.number_account && (
                            <small className="text-danger">
                              A conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="account_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9"
                            mask="*"
                            maskChar=""
                            value={store && store.account_digit}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.account_digit && (
                            <small className="text-danger">
                              O digito da conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="account_kind">Tipo de conta</Label>
                        <Input
                          type="select"
                          id="account_kind"
                          name="account_kind"
                          value={store && store.account_kind}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="0">Conta Corrente</option>
                          <option value="1">Poupança</option>
                        </Input>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    {isQista && !corbanName ? (
                      <>
                        <Row>
                          <Col lg="2  mb-4">
                            <Label for="kind_table">
                              Definir uma comissão {isQista ? 'CDC?' : 'fgts'}
                            </Label>
                            <Input
                              type="select"
                              id="has_commission"
                              name="has_commission"
                              value={store && store.has_commission}
                              onChange={handleOnChange}
                            >
                              <option>Selecione o tipo</option>
                              <option value="yes">Sim</option>
                              <option value="no">Não</option>
                            </Input>
                          </Col>
                        </Row>

                        {store.has_commission != '' &&
                          store.has_commission == 'yes' && (
                            <>
                              <hr />
                              <h3 className="mb-4">Tabela CDC</h3>
                              <h3 className="mb-4">Acima de R$ 250,00</h3>
                              {fgtsCommissions
                                .filter((item) => item.tac != 30)
                                .map((commission, index) => (
                                  <div key={index}>
                                    <Row>
                                      <Col lg="4">
                                        <FormGroup>
                                          {index == 0 && (
                                            <label
                                              className="form-control-label"
                                              htmlFor="basic"
                                            >
                                              Tabela
                                            </label>
                                          )}
                                          <Input
                                            type="text"
                                            name="basic"
                                            className="form-control form-control-alternative"
                                            id="basic"
                                            value={commission.name}
                                            inputRef={register({
                                              required: true,
                                            })}
                                            disabled
                                          />
                                        </FormGroup>
                                      </Col>
                                      {/* Apos mudar o tipo de comissão aparece o que é pertinente */}
                                      <>
                                        <Col lg="2">
                                          <FormGroup>
                                            {index == 0 && (
                                              <label
                                                className="form-control-label"
                                                htmlFor="value"
                                              >
                                                Valor
                                              </label>
                                            )}
                                            <Input
                                              type="text"
                                              name="value_percentage"
                                              className="form-control form-control-alternative"
                                              id="value_percentage"
                                              // placeholder="99%"
                                              value={
                                                commission && commission.value
                                              }
                                              inputRef={register({
                                                required: true,
                                              })}
                                              onChange={(event) =>
                                                handleOnChangeCommissionFgts(
                                                  event,
                                                  commission.id,
                                                  index
                                                )
                                              }
                                              onBlur={(event) =>
                                                insertPercentage(event, index)
                                              }
                                            />
                                            {errors.value && (
                                              <small className="text-danger">
                                                Valor é obrigatório
                                              </small>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>

                                      <Col lg="2">
                                        Habilitado?
                                        <Input
                                          type="select"
                                          id="enabled"
                                          value={
                                            commission && commission.enabled
                                          }
                                          name="enabled"
                                          onChange={(event) =>
                                            handleOnChangeCommissionFgts(
                                              event,
                                              commission.id,
                                              index
                                            )
                                          }
                                        >
                                          <option value="true">Sim</option>
                                          <option value="false">Não</option>
                                        </Input>
                                      </Col>

                                      {/* <div className={`${index == 0 ? "mt-4" : "mt-2"}`}>
                                  <Button color="danger"
                                    onClick={() =>   handleClickCommissionRemoveFgts(index)}
                                  close />
                                </div> */}
                                    </Row>
                                  </div>
                                ))}
                              <h3 className="mb-4">De R$ 100,00 a R$ 249,99</h3>
                              {fgtsCommissions
                                .filter((item) => item.tac == 30)
                                .map((commission, index) => (
                                  <div key={index}>
                                    <Row>
                                      <Col lg="4">
                                        <FormGroup>
                                          {index == 0 && (
                                            <label
                                              className="form-control-label"
                                              htmlFor="basic"
                                            >
                                              Tabela
                                            </label>
                                          )}
                                          <Input
                                            type="text"
                                            name="basic"
                                            className="form-control form-control-alternative"
                                            id="basic"
                                            value={commission.name}
                                            inputRef={register({
                                              required: true,
                                            })}
                                            disabled
                                          />
                                        </FormGroup>
                                      </Col>
                                      {/* Apos mudar o tipo de comissão aparece o que é pertinente */}
                                      <>
                                        <Col lg="2">
                                          <FormGroup>
                                            {index == 0 && (
                                              <label
                                                className="form-control-label"
                                                htmlFor="value"
                                              >
                                                Valor
                                              </label>
                                            )}
                                            <Input
                                              type="text"
                                              name="value_percentage"
                                              className="form-control form-control-alternative"
                                              id="value_percentage"
                                              // placeholder="99%"
                                              value={
                                                commission && commission.value
                                              }
                                              inputRef={register({
                                                required: true,
                                              })}
                                              onChange={(event) =>
                                                handleOnChangeCommissionFgts(
                                                  event,
                                                  commission.id,
                                                  index
                                                )
                                              }
                                              onBlur={(event) =>
                                                insertPercentage(event, index)
                                              }
                                            />
                                            {errors.value && (
                                              <small className="text-danger">
                                                Valor é obrigatório
                                              </small>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>

                                      <Col lg="2">
                                        Habilitado?
                                        <Input
                                          type="select"
                                          id="enabled"
                                          value={
                                            commission && commission.enabled
                                          }
                                          name="enabled"
                                          onChange={(event) =>
                                            handleOnChangeCommissionFgts(
                                              event,
                                              commission.id,
                                              index
                                            )
                                          }
                                        >
                                          <option value="true">Sim</option>
                                          <option value="false">Não</option>
                                        </Input>
                                      </Col>

                                      {/* <div className={`${index == 0 ? "mt-4" : "mt-2"}`}>
                                  <Button color="danger"
                                    onClick={() =>   handleClickCommissionRemoveFgts(index)}
                                  close />
                                </div> */}
                                    </Row>
                                  </div>
                                ))}
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {!isQista && (
                          <>
                            <Row>
                              <Col lg="2">
                                <Label for="has_commission">
                                  Definir comissão
                                </Label>
                                <Input
                                  type="select"
                                  id="has_commission"
                                  name="has_commission"
                                  value={store && store.has_commission}
                                  onChange={handleOnChange}
                                >
                                  <option>Selecione o tipo</option>
                                  <option value="yes">Sim</option>
                                  <option value="no">Não</option>
                                </Input>
                              </Col>
                              {store.has_commission == 'yes' ? (
                                <Col lg="2">
                                  <Label for="commission_type">
                                    Tipo de comissão
                                  </Label>
                                  <Input
                                    type="select"
                                    id="commission_type"
                                    name="commission_type"
                                    value={store && store.commission_type}
                                    onChange={handleOnChange}
                                  >
                                    <option>Selecione o tipo</option>
                                    <option value="0">Percentual</option>
                                    <option value="1">Fixo</option>
                                  </Input>
                                </Col>
                              ) : (
                                false
                              )}
                              {store.has_commission == 'yes' &&
                              store.commission_type == '0' ? (
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="value"
                                    >
                                      Valor percentual
                                    </label>
                                    <Input
                                      type="text"
                                      name="value_percentage"
                                      className="form-control form-control-alternative"
                                      id="value_percentage"
                                      placeholder="99%"
                                      value={store && store.value}
                                      inputRef={register({ required: true })}
                                      onChange={handleOnChange}
                                      onBlur={insertPercentage}
                                    />
                                    {errors.value && (
                                      <small className="text-danger">
                                        Valor é obrigatório
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                              ) : store.has_commission == 'yes' &&
                                store.commission_type == '1' ? (
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="value"
                                    >
                                      Valor Fixo
                                    </label>
                                    <CurrencyInput
                                      prefix="R$"
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      type="text"
                                      name="value_fix"
                                      className="form-control form-control-alternative"
                                      id="value_fix"
                                      placeholder="R$999,99"
                                      value={store && store.value}
                                      inputRef={register({ required: true })}
                                      onChange={handleOnChangeValueFix}
                                      onBlur={insertMoney}
                                    />
                                    {errors.value && (
                                      <small className="text-danger">
                                        Valor é obrigatório
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                              ) : (
                                false
                              )}
                            </Row>
                            <Row>
                              {store.has_commission == 'yes' ? (
                                <Col lg="2">
                                  <Label for="exampleselect">
                                    Definir bônus
                                  </Label>
                                  <Input
                                    type="select"
                                    id="exampleselect"
                                    name="active_base_trigger"
                                    value={store && store.active_base_trigger}
                                    onChange={handleOnChange}
                                  >
                                    <option>Selecione o tipo</option>
                                    <option value="yes">Sim</option>
                                    <option value="no">Não</option>
                                  </Input>
                                </Col>
                              ) : (
                                false
                              )}
                              {store.has_commission == 'yes' &&
                              store.active_base_trigger == 'yes' ? (
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="trigger"
                                    >
                                      Bônus
                                    </label>
                                    <InputMask
                                      type="text"
                                      name="trigger"
                                      className="form-control form-control-alternative"
                                      id="trigger"
                                      placeholder="99%"
                                      value={store && store.trigger}
                                      inputRef={register({ required: true })}
                                      onChange={handleOnChange}
                                      onBlur={insertPercentage}
                                    />
                                    {errors.trigger && (
                                      <small className="text-danger">
                                        Bônus é obrigatório
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                              ) : (
                                false
                              )}
                            </Row>{' '}
                            {/*  final dados bancarios */}
                          </>
                        )}
                        <Row>
                          {store.has_commission == 'yes' && (
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="commission_fgts"
                                >
                                  Valor comissão {isQista ? 'CDC' : 'fgts'}
                                </label>
                                <Input
                                  type="text"
                                  name="commission_fgts"
                                  className="form-control form-control-alternative"
                                  id="commission_fgts"
                                  placeholder="99%"
                                  value={store && store.commission_fgts}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                  onBlur={insertPercentage}
                                />
                                {errors.commission_fgts && (
                                  <small className="text-danger">
                                    Valor é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Contatos</h6>
                  <div className="pl-lg-4">
                    {contactFields.map((contactField, index) => (
                      <div key={index}>
                        <Row className="mt-4">
                          <Col lg="2">
                            {index == 0 && (
                              <Label for="exampleselect">Tipo de contato</Label>
                            )}
                            <Input
                              type="select"
                              name="select"
                              id="exampleselect"
                              value={contactField && contactField.type_contact}
                              onChange={(event) =>
                                handleOnChangeTypeContact(event, index)
                              }
                            >
                              <option>Selecione o tipo</option>
                              <option value="email">Email</option>
                              <option value="mobile">Celular</option>
                            </Input>
                          </Col>
                          {contactField.type_contact == 'mobile' ? (
                            <Col lg="3">
                              <FormGroup>
                                {index == 0 && (
                                  <label
                                    className="form-control-label"
                                    htmlFor="mobile_contact"
                                  >
                                    Celular
                                  </label>
                                )}
                                <InputMask
                                  type="text"
                                  name="mobile_contact"
                                  className="form-control form-control-alternative"
                                  id="mobile_contact"
                                  placeholder="(99) 99999-9999"
                                  mask="(99) 99999-9999"
                                  maskChar=""
                                  value={contactField && contactField.mobile}
                                  inputRef={register({ required: true })}
                                  onChange={(event) =>
                                    handleOnChangeContact(index, event)
                                  }
                                />
                                {errors.mobile_contact && (
                                  <small className="text-danger">
                                    Banco é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          ) : contactField.type_contact == 'email' ? (
                            <Col lg="3">
                              <FormGroup>
                                {index == 0 && (
                                  <label
                                    className="form-control-label"
                                    htmlFor="email_contact"
                                  >
                                    Email
                                  </label>
                                )}
                                <input
                                  type="email_contact"
                                  name="email_contact"
                                  className="form-control form-control-alternative "
                                  id="email_contact"
                                  value={contactField && contactField.email}
                                  placeholder="example@example.com"
                                  ref={register({ required: true })}
                                  onChange={(event) =>
                                    handleOnChangeContact(index, event)
                                  }
                                />
                                {errors.email_contact && (
                                  <small className="text-danger">
                                    O email é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          ) : (
                            false
                          )}
                          <div className="mt-5">
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => handleClickContactRemove(index)}
                              close
                            />
                          </div>
                        </Row>
                      </div>
                    ))}
                    <br></br>
                    <Button color="primary" onClick={handleClickContact}>
                      Adicionar Contato
                    </Button>
                  </div>
                  {!isQista && (
                    <Col lg="2">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="support_whats"
                        >
                          Suporte via whatsapp?
                        </Label>
                        <CustomInput
                          type="select"
                          id="support_whats"
                          name="support_whats"
                          onChange={handleOnChange}
                          value={store && store.support_whats}
                        >
                          <option value="">Selecionar</option>
                          <option value="true">Sim</option>
                          <option value="false">Não</option>
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  )}
                  <h6 className="heading-small text-muted mb-4">Requisições FGTS</h6>
                  <Col lg="2">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="allow_search_api_fgts"
                      >
                        Habilitar API Fgts?
                      </Label>
                      <CustomInput
                        type="select"
                        id="allow_search_api_fgts"
                        name="allow_search_api_fgts"
                        onChange={handleOnChange}
                        value={store && store.allow_search_api_fgts}
                      >
                        <option value="">Selecionar</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="basic">
                            Consultas FGTS realizadas no mês
                          </label>
                          <Input
                            type="text"
                            name="current_qtd_uy3_req"
                            className="form-control form-control-alternative"
                            id="current_qtd_uy3_req"
                            value={store.current_qtd_uy3_req}
                            disabled
                          />
                          <small className="text-danger">
                            Quantas consultas foram realizadas para o parceiro no mês atual.
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> {titleButton}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StoreForm;
