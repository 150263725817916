import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CorbanForm from '../../components/CorbanForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';

const UpdateCorban = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [corban, setCorban] = useState({
    social_reason: '',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    fgts_has_commission: '',
    agency_digit: '',
    account_digit: '',
    calculate_type: '',
    type_corban: '',
    referrer_id: '',
    has_proposed_permission: '',
    simplified_cut_value: '',
    product: '',
    product_cdc: '',
    product_fgts: '',
    periodicity: '',
  });
  const [contactFields, setContactFields] = useState([]);
  const [fgtsBasicInterests, setFgtsBasicInterest] = useState();
  const [commissions, setCommissions] = useState([]);
  const [fgtsCommissions, setFgtsCommissions] = useState([]);
  const [basicInterests, setBasicInterest] = useState();
  const [banks, setBanks] = useState([]);
  const [referrers, setReferrers] = useState([]);
  const [cdcCommissions, setCdcCommission] = useState([
    {
      id: 1,
      tac: 12,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      removeId: true,
      is_cdc: true,
      kind: 'grade',
    },
    {
      id: 2,
      tac: 18,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      removeId: true,
      is_cdc: true,
      kind: 'grade',
    },
    {
      id: 3,
      tac: 30,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      removeId: true,
      is_cdc: true,
      kind: 'grade',
    },
    {
      id: 4,
      tac: 30,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      removeId: true,
      is_cdc: true,
      kind: 'grade',
    },
  ]);

  const [installmentFields, setIntallmentFields] = useState([]);

  function handleOnChangeInstallmentFields(index, indexField, event) {
    if (event.target.name == 'has_secure') {
      const values = [...commissions];
      const valuesInstallments = [...installmentFields];
      values[index].has_secure = event.target.value;
      valuesInstallments[index].installments.map((item) => {
        return {
          ...item,
          has_secure: event.target.value,
        };
      });

      setCommissions(values);

      setIntallmentFields(valuesInstallments);
    } else {
      const values = [...installmentFields];
      if (event.target.name == 'installment') {
        values[index].installments[indexField]['installment'] =
          event.target.value;
      } else if (event.target.name == 'value') {
        values[index].installments[indexField]['value'] = event.target.value;
      }

      setIntallmentFields(values);
    }
  }

  function handleOnChangeCommissionCdc(event, id, index) {
    const values = [...cdcCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }

    values[index][event.target.name] = event.target.value;
    setCdcCommission(values);
  }

  function handleClickInstallmentFields(index) {
    const values = [...installmentFields];
    values[index]['installments'] = [
      ...values[index].installments,
      { installment: '', value: '' },
    ];

    setIntallmentFields(values);
  }

  function removeInstallmentFields(index, indexField) {
    const values = [...installmentFields];
    const installments = [...values[index].installments];

    installments[indexField]['_destroy'] = true;
    // installments.splice(indexField, 1);

    values[index]['installments'] = installments;
    //
    setIntallmentFields(values);
  }

  async function getTableSettings() {
    showLoader();

    try {
      const response = await api.get(`table_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setFgtsCommissions(
        response.data.map((item) => {
          return { ...item, removeId: true };
        })
      );
      getCorban(
        response.data.map((item) => {
          return { ...item, removeId: true };
        })
      );
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  }

  async function getCorban(commissions = null) {
    showLoader();
    try {
      const { data } = await api.get(`corbans/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const dataCommission = data.commission_bases.map((item) => {
        return {
          ...item,

          id: item.id,
          commission_type: item.commission_type == 'percentage' ? '0' : '1',
          has_secure: item?.installments[0]?.has_secure,
          value:
            item.commission_type == 'percentage'
              ? `${item.value}`
              : `${item.value}`,
          trigger: item.trigger != null ? `${item.trigger}` : '',
          installments: item.installments,
        };
      });

      console.log('COMISSAO', dataCommission);

      setContactFields(data.contact);
      // if(data.entity.has_commission == true) {
      if (data.commission_bases.length > 0) {
        setCommissions(dataCommission);

        setIntallmentFields(dataCommission);
      }

      // }
      getBasicInterest(data);

      if (data.entity.fgts_has_commission == true) {
        if (data.fgts_commission.length > 0) {
          setFgtsCommissions([
            ...commissions.filter((item) => item.kind !== data.kind),
            ...data.fgts_commission
              .filter((item) => !item.is_cdc)
              .map((item) => {
                return {
                  ...item,
                  removeId: false,
                  commission_type:
                    item.commission_type == 'percentage' ? '0' : '1',
                  value:
                    item.commission_type == 'percentage'
                      ? `${item.value}`
                      : `${item.value}`,
                  trigger: '',
                };
              }),
          ]);
        } else {
        }
      }

      if (data.entity.has_cdc_commission == true) {
        if (data.fgts_commission.filter((item) => item.is_cdc).length > 0) {
          setCdcCommission([
            ...data.fgts_commission
              .filter((item) => item.is_cdc)
              .map((item) => {
                return {
                  ...item,
                  removeId: false,
                  commission_type:
                    item.commission_type == 'percentage' ? '0' : '1',
                  value:
                    item.commission_type == 'percentage'
                      ? `${item.value}`
                      : `${item.value}`,
                  trigger: '',
                };
              }),
          ]);
        } else {
        }
      }

      setCorban({
        id: data.id,
        calculate_type: data.calculate_type == 'method_1' ? '0' : '1',
        type_corban: data.corban_type == 'intern' ? '0' : '1',
        webhook_url: data.webhook_url,
        kind_table: data.kind,
        kind_integrator: data.kind_integrator,
        cpf_cnpj: data.entity.cpf_cnpj,
        name: data.entity.name,
        social_reason: data.entity.social_reason,
        commission_fgts: data.entity.commission_fgts,
        product: data.entity.product,
        product_fgts: data.entity.product_fgts,
        product_cdc: data.entity.product_cdc,
        periodicity: data.entity.periodicity,
        periodicity_fgts: data.entity.periodicity_fgts,
        periodicity_cdc: data.entity.periodicity_cdc,
        has_uy3_create: data.entity.has_uy3_create == true ? 'yes' : 'no',
        has_fgts_create: data.entity.has_fgts_create == true ? 'yes' : 'no',
        has_credit_create: data.entity.has_credit_create == true ? 'yes' : 'no',
        has_cdc_create: data.entity.has_cdc_create == true ? 'yes' : 'no',
        has_secure: data.entity.has_secure == true ? 'yes' : 'no',
        has_cdc_commission:
          data.entity.has_cdc_commission == true ? 'yes' : 'no',
        zip_code: data.address.zip_code,
        street: data.address.street,
        number: data.address.number,
        district: data.address.district,
        user_id: data.user_id,
        city: data.address.city,
        state: data.address.state,
        complement: data.address.complement,
        bank_name: `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`,
        kind_pix: data.bank_account.kind_pix,
        pix: data.bank_account.pix,
        account_kind: data.bank_account.kind,
        agency_account: data.bank_account.agency_account,
        agency_digit: data.bank_account.agency_digit,
        account_digit: data.bank_account.account_digit,
        number_account: data.bank_account.number_account,
        has_commission: data.entity.has_commission == true ? 'yes' : 'no',
        fgts_has_commission:
          data.entity.fgts_has_commission == true ? 'yes' : 'no',
        entity_id: data.entity.id,
        address_id: data.address.id,
        bank_id: data.bank_account.id,
        user_cpf: data.legal_representate.cpf,
        user_name: data.legal_representate.name,
        user_email: data.legal_representate.email,
        user_mobile: data.legal_representate.mobile,
        referrer_id: data.referrer_id,
        referrer_name: data.referrer_name,
        has_proposed_permission:
          data?.has_proposed_permission == true ? 'yes' : 'no',
        simplified_payment: data.simplified_payment,
        simplified_cut_value: data.simplified_cut_value,
        current_qtd_uy3_req: data.current_qtd_uy3_req,
        qtd_uy3_requests_monthly: data.qtd_uy3_requests_monthly
      });
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }

  useEffect(() => {
    async function getBanks() {
      showLoader();

      try {
        const response = await api.get(`banks?q[active_eq]=true`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`,
          };
        });

        setBanks(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }
    getFgtsBasicInterest();

    async function getReferrers() {
      showLoader();

      try {
        const response = await api.get(`referrers`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: value.id,
            label: value.name,
          };
        });

        setReferrers(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível listar os indicador.');
      }
    }
    getBanks();
    getReferrers();
  }, []);

  async function getBasicInterest(dataCorban = null) {
    if (!basicInterests || (basicInterests && basicInterests.length <= 0)) {
      showLoader();

      try {
        const response = await api.get(`basic_interests`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        setBasicInterest(response.data);
        let arrayCommissions = [];
        response.data.forEach((item) => {
          arrayCommissions = [
            ...arrayCommissions,
            {
              basic_interest: item.value,
              basic_interest_id: item.id,
              commission_type: '',
              has_secure: true,
              value: '',
              trigger: '',
            },
          ];
        });
        if (dataCorban && dataCorban.commission_bases.length <= 0) {
          setCommissions(arrayCommissions);
        }

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
  }

  async function getFgtsBasicInterest() {
    if (!fgtsCommissions || (fgtsCommissions && fgtsCommissions.length <= 0)) {
      showLoader();

      try {
        //
        // setFgtsBasicInterest(response.data);
        // let arrayCommissions = []
        // response.data.forEach(item => {
        //   arrayCommissions = [...arrayCommissions, {basic_interest_default: item.default_value, basic_interest: item.value, fgts_basic_interest_id: item.id, commission_type: 0, value: parsePercentage(item.default_value), trigger: ''}]
        // })
        let arrayCommissions = [
          {
            id: 1,
            tac: 12,
            name: 'Money | Juros 1,79%',
            commission_type: 0,
            value: 12.5,
            rate: 1.79,
            value_greater_thousand: 12.5,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 2,
            tac: 12,
            name: 'Money | Juros 1,69%',
            commission_type: 0,
            value: 9,
            rate: 1.69,
            value_greater_thousand: 9,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 3,
            tac: 12,
            name: 'Money | Juros 1,55%',
            commission_type: 0,
            value: 7,
            rate: 1.55,
            value_greater_thousand: 7,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },

          {
            id: 4,
            tac: 15,
            name: 'Laranja | Juros 1,79%',
            commission_type: 0,
            value: 15,
            rate: 1.79,
            value_greater_thousand: 15,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 5,
            tac: 18,
            name: 'Gold | Juros 1,79%',
            commission_type: 0,
            value: 17,
            rate: 1.79,
            value_greater_thousand: 17,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 6,
            tac: 30,
            name: 'Smart | Juros 1,79%',
            commission_type: 0,
            value: 15,
            rate: 1.79,
            value_greater_thousand: 15,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 7,
            tac: 30,
            name: 'Smart | Juros 1,69%',
            commission_type: 0,
            value: 9,
            rate: 1.69,
            value_greater_thousand: 9,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 8,
            tac: 30,
            name: 'Smart | Juros 1,55%',
            commission_type: 0,
            value: 7,
            rate: 1.55,
            value_greater_thousand: 7,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          //Acordo

          {
            id: 9,
            tac: 12,
            name: 'Money | Juros 1,79%',
            commission_type: 0,
            value: 14,
            rate: 1.79,
            value_greater_thousand: 14,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 10,
            tac: 12,
            name: 'Money | Juros 1,69%',
            commission_type: 0,
            value: 11,
            rate: 1.69,
            value_greater_thousand: 11,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 11,
            tac: 12,
            name: 'Money | Juros 1,55%',
            commission_type: 0,
            value: 8.5,
            rate: 1.55,
            value_greater_thousand: 8.5,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },

          {
            id: 12,
            tac: 15,
            name: 'Laranja | Juros 1,79%',
            commission_type: 0,
            value: 17,
            rate: 1.79,
            value_greater_thousand: 17,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 13,
            tac: 18,
            name: 'Gold | Juros 1,79%',
            commission_type: 0,
            value: 19,
            rate: 1.79,
            value_greater_thousand: 19,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 14,
            tac: 30,
            name: 'Smart | Juros 1,79%',
            commission_type: 0,
            value: 17,
            rate: 1.79,
            value_greater_thousand: 17,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 15,
            tac: 30,
            name: 'Smart | Juros 1,69%',
            commission_type: 0,
            value: 11,
            rate: 1.69,
            value_greater_thousand: 11,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },
          {
            id: 16,
            tac: 30,
            name: 'Smart | Juros 1,55%',
            commission_type: 0,
            value: 9,
            rate: 1.55,
            value_greater_thousand: 9,
            enabled: true,
            removeId: true,
            kind: 'acordo',
          },

          // especial
          {
            id: 17,
            tac: 12,
            name: 'Money | Juros 1,79%',
            commission_type: 0,
            value: 16,
            rate: 1.79,
            value_greater_thousand: 16,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 18,
            tac: 12,
            name: 'Money | Juros 1,69%',
            commission_type: 0,
            value: 13,
            rate: 1.69,
            value_greater_thousand: 13,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 19,
            tac: 12,
            name: 'Money | Juros 1,55%',
            commission_type: 0,
            value: 10.5,
            rate: 1.55,
            value_greater_thousand: 10.5,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },

          {
            id: 20,
            tac: 15,
            name: 'Laranja | Juros 1,79%',
            commission_type: 0,
            value: 19,
            rate: 1.79,
            value_greater_thousand: 19,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 21,
            tac: 18,
            name: 'Gold | Juros 1,79%',
            commission_type: 0,
            value: 21,
            rate: 1.79,
            value_greater_thousand: 21,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 22,
            tac: 30,
            name: 'Smart | Juros 1,79%',
            commission_type: 0,
            value: 21,
            rate: 1.79,
            value_greater_thousand: 21,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 23,
            tac: 30,
            name: 'Smart | Juros 1,69%',
            commission_type: 0,
            value: 13,
            rate: 1.69,
            value_greater_thousand: 13,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
          {
            id: 24,
            tac: 30,
            name: 'Smart | Juros 1,55%',
            commission_type: 0,
            value: 11,
            rate: 1.55,
            value_greater_thousand: 11,
            enabled: true,
            removeId: true,
            kind: 'especial',
          },
        ];

        getTableSettings();
        // setFgtsCommissions(arrayCommissions);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
  }

  function handleOnChangeCommission(
    event,
    index,
    basic_interest_id,
    basic_interest
  ) {
    const values = [...commissions];

    if (
      event.target.name == 'value_fix' ||
      event.target.name == 'value_percentage' ||
      event.target.name == 'trigger'
    ) {
      if (event.target.name == 'value_fix') {
        values[index]['value'] = formatarMoeda(event.target.value);
      } else {
        if (event.target.name != 'trigger') {
          values[index]['value'] = event.target.value.replace('%', '');
        } else {
          values[index]['trigger'] = event.target.value.replace('%', '');
        }
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
      values[index][event.target.name] = event.target.value;
    } else {
      values[index][event.target.name] = event.target.value;
    }
    setCommissions(values);
  }

  function handleOnChangeCommissionFgts(event, id, index) {
    const values = [...fgtsCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }
    values[index][event.target.name] = event.target.value;
    setFgtsCommissions(values);
  }

  async function onSubmit(data) {
    showLoader();

    let commissionParams = [];
    installmentFields.map((item) => {
      item.installments.map((i) => {
        commissionParams = [
          ...commissionParams,
          {
            id: i.id,
            basic_interest_id: item.interest_id,
            basic_interest: item.interest,
            commission_type: 'percentage',
            value: i.value,
            has_secure: item.has_secure,
            installment: i.installment,
            _destroy: i._destroy,
          },
        ];
      });
    });

    parseCommissionType();

    if (!corban.kind_pix) {
      toastError('Selecione o tipo de chave pix');
      hideLoader();
      return;
    }

    if (corban.kind_pix != 'random' && !corban.pix) {
      toastError('Preencher campo pix');
      hideLoader();
      return;
    }

    //

    data = {
      corban: {
        current_qtd_uy3_req: corban.current_qtd_uy3_req,
        qtd_uy3_requests_monthly: corban.qtd_uy3_requests_monthly,
        calculate_type: parseInt(corban.calculate_type, 10),
        corban_type: parseInt(corban.type_corban, 10),
        referrer_id: corban.referrer_id,
        kind_integrator: corban.kind_integrator,
        webhook_url: corban.webhook_url,
        kind: corban.kind_table,
        simplified_payment: corban.simplified_payment,
        simplified_cut_value: corban.simplified_cut_value,
        has_proposed_permission:
          corban.has_proposed_permission == 'yes' ? true : false,
        entity_attributes: {
          id: corban.entity_id,
          cpf_cnpj: corban.cpf_cnpj,
          name: corban.name,
          product: corban.product,
          product_cdc: corban.product_cdc,
          product_fgts: corban.product_fgts,
          periodicity: corban.periodicity,
          periodicity_fgts: corban.periodicity_fgts,
          periodicity_cdc: corban.periodicity_cdc,
          commission_fgts: corban.commission_fgts,
          social_reason: corban.social_reason,
          fgts_has_commission: true,
          has_uy3_create: corban.has_uy3_create == 'yes' ? true : false,
          has_fgts_create: corban.has_fgts_create == 'yes' ? true : false,
          has_cdc_create: corban.has_cdc_create == 'yes' ? true : false,
          has_credit_create: corban.has_credit_create == 'yes' ? true : false,
          has_secure: corban.has_secure == 'yes' ? true : false,
          has_cdc_commission: corban.has_cdc_commission == 'yes' ? true : false,
          has_commission: corban.has_commission == 'yes' ? true : false,
          address_attributes: {
            id: corban.address_id,
            zip_code: corban.zip_code,
            street: corban.street,
            number: corban.number,
            district: corban.district,
            city: corban.city,
            state: corban.state,
            complement: corban.complement,
          },
          bank_account_attributes: {
            id: corban.bank_id,
            number_bank: corban.bank_name.split('-')[0],
            name_bank: corban.bank_name.split('-')[1],
            agency_account: corban.agency_account,
            agency_digit: corban.agency_digit,
            number_account: corban.number_account,
            account_digit: corban.account_digit,
            kind: corban.account_kind,
            kind_pix: corban.kind_pix,
            pix: corban.pix,
          },
          contacts_attributes: [...contactFields],
          commission_bases_attributes: [
            ...commissionParams.map((item) => {
              return {
                ...item,
                value:
                  item.commission_type == '0'
                    ? item.value.replace(',', '.')
                    : item.value,
              };
            }),
          ],
          fgts_commissions_attributes: [
            ...cdcCommissions.map((item) => {
              return {
                ...item,
                is_cdc: true,
                id: item.removeId ? null : item.id,
                value: item.value,
              };
            }),
            ...fgtsCommissions
              .filter((item) => item.kind === corban.kind_table)
              .map((item) => {
                return {
                  ...item,
                  id: item.removeId ? null : item.id,
                  value: item.value,
                };
              }),
          ],
        },
      },
    };

    if (corban.password) {
      data = {
        ...data,
        corban: {
          ...data.corban,
          users_attributes: [
            {
              id: corban.user_id,
              password: corban.password,
            },
          ],
        },
      };
    }

    try {
      await api.put(`corbans/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Correspondetente Bancário atualizado com sucesso');

      props.history.push('/admin/corbans');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error;

          responseErrors.forEach((e) => {
            if (e.key == 'Documento' && e.model == 'users') {
              setError('user_cpf', 'inválido');
            } else if (e.key == 'Documento' && e.model == 'entity') {
              setError('cpf_cnpj', 'inválido');
            } else if (e.key == 'email' && e.model == 'users') {
              setError('user_email', 'inválido');
            }
          });
        }
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parsePercentage(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('%', '').replace(',', ''));
    } else {
      return value;
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function handleOnChange(e) {
    const values = { ...corban };

    if (e.target.name == 'value_percentage') {
      setCorban({ ...corban, ['value']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'trigger') {
      setCorban({ ...corban, ['trigger']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'commission_type' && values['value'] != '') {
      setCorban({ ...corban, [e.target.name]: e.target.value, value: '' });
    } else if (e.target.name == 'agency_digit') {
      setCorban({ ...corban, ['agency_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'account_digit') {
      setCorban({ ...corban, ['account_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'cpf_cnpj') {
      if (e.target.value.length < 19) {
        let cpf_cnpj = e.target.value;

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})(\d)/, '$1.$2');

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

        cpf_cnpj = cpf_cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');

        cpf_cnpj = cpf_cnpj.replace(/(\d{4})(\d)/, '$1-$2');
        setCorban({ ...corban, [e.target.name]: cpf_cnpj });
      }
    } else if (e.target.name == 'user_cpf') {
      if (e.target.value.length < 15) {
        let user_cpf = e.target.value;
        if (user_cpf.length >= 12) {
          user_cpf = user_cpf
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          user_cpf = user_cpf.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setCorban({ ...corban, [e.target.name]: user_cpf });
      }
    } else if (e.target.name == 'user_mobile') {
      if (e.target.value.length < 16) {
        let user_mobile = e.target.value;

        if (user_mobile.length > 10) {
          user_mobile = user_mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          user_mobile = user_mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setCorban({ ...corban, [e.target.name]: user_mobile });
      }
    } else {
      setCorban({ ...corban, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e) {
    setCorban({
      ...corban,
      value: parseCurrency(e),
    });
  }

  function handleOnAgencyDigits(e) {
    setCorban({
      ...corban,
      agency_digit: parseDigit(e.target.agency_digit),
    });
  }

  function handleOnAccountDigits(e) {
    setCorban({
      ...corban,
      account_digit: parseDigit(e.target.account_digit),
    });
  }

  function parseCommissionType() {
    const values = [...commissions];
    values.map((value) => {
      value.commission_type = parseInt(value.commission_type, 10);
      if (value.value[0] == 'R') {
        value.value = value.value.replace('R$', '').replace(',', '');
      }
    });

    setCommissions(values);

    const valuesFgts = [...fgtsCommissions];
    valuesFgts.map((value) => {
      value.commission_type = parseInt(value.commission_type, 10);
      if (value.value[0] == 'R') {
        value.value = value.value.replace('R$', '').replace(',', '');
      }
    });

    setFgtsCommissions(valuesFgts);
  }

  function handleOnChange(e) {
    setCorban({ ...corban, [e.target.name]: e.target.value });
  }

  function dataAddress({ street, district, city, state }) {
    setCorban({
      ...corban,
      street: street,
      district: district,
      city: city,
      state: state,
    });
  }

  function handleOnChangeBank(e) {
    setCorban({ ...corban, bank_name: e.value });
  }

  function insertPercentage(e, index) {
    const values = [...commissions];
    if (e.target.name == 'trigger') {
      if (e.target.value.charAt(2) != '%') {
        values[index]['trigger'] = `${e.target.value}%`;
      }
    } else {
      if (e.target.value.charAt(2) != '%') {
        values[index]['value'] = `${e.target.value}%`;
      }
    }

    setCommissions(values);
  }

  function insertPercentageCorban(e) {
    setCorban({ ...corban, [e.target.name]: `${e.target.value}%` });
  }

  function insertMoney(e, index) {
    const values = [...commissions];
    if (e.target.value.charAt(0) != 'R') {
      values[index]['value'] = `R$ ${e.target.value}`;
    }
    setCommissions(values);
  }

  function handleOnChangeReferrer(e) {
    setCorban({ ...corban, referrer_id: e.value, referrer_name: e.label });
  }

  return (
    <>
      <CustomerHeader />
      <CorbanForm
        corban={corban}
        basicInterests={basicInterests}
        fgtsBasicInterests={fgtsBasicInterests}
        contactFields={contactFields}
        commissions={commissions}
        fgtsCommissions={fgtsCommissions}
        cdcCommissions={cdcCommissions}
        banks={banks}
        hasEdit
        title="Atualizar Correspondente Bancário"
        titleButton="Atualizar Correspondente Bancário"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeBank={handleOnChangeBank}
        dataAddress={dataAddress}
        handleOnChangeCommission={handleOnChangeCommission}
        handleOnChangeCommissionFgts={handleOnChangeCommissionFgts}
        handleOnChangeCommissionCdc={handleOnChangeCommissionCdc}
        insertPercentage={insertPercentage}
        insertPercentageCorban={insertPercentageCorban}
        insertMoney={insertMoney}
        controller={'edit'}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        referrers={referrers}
        handleOnChangeReferrer={handleOnChangeReferrer}
        handleOnChangeInstallmentFields={handleOnChangeInstallmentFields}
        installmentFields={installmentFields}
        setIntallmentFields={setIntallmentFields}
        removeInstallmentFields={removeInstallmentFields}
        handleClickInstallmentFields={handleClickInstallmentFields}
      />
    </>
  );
};

export default UpdateCorban;
