/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
import { visibilitySidebar } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  NavDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Button,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
  Card,
  CardBody,
} from 'reactstrap';

var ps;

const Sidebar = (props) => {
  const { user } = useContext(AuthContext);
  // useEffect(() => {
  //   visibilitySidebar(routes)
  // })
  const [showFgtsCommissionData, setShowFgtsCommissionData] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.path == '/customer-services' ||
        prop.path == '/contracts' ||
        prop.path == '/customer-services/create' ||
        prop.path == '/fgts-customer-services' ||
        prop.path == '/fgts-contracts' ||
        prop.path == '/fgts-customer-services/create' ||
        prop.path == '/fgts_commission' ||
        prop.path == '/cdc_commission' ||
        prop.path == '/commission'
      ) {
        return false;
      } else {
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    });
  };

  const renderDataCommissionLink = (routes) => {
    const hasFgtsDataPermission = routes.filter(
      (prop, key) => prop.path == '/fgts_commission'
    );
    const hasCdcDataPermission = routes.filter(
      (prop, key) => prop.path == '/cdc_commission'
    );
    const hasCardCommission = routes.filter(
      (prop, key) => prop.path == '/commission'
    );

    if (
      (hasFgtsDataPermission && hasFgtsDataPermission.length > 0) ||
      (hasCdcDataPermission && hasCdcDataPermission.length > 0) ||
      (hasCardCommission && hasCardCommission.length > 0)
    ) {
      return (
        <NavItem className="" style={{ cursor: 'pointer' }}>
          <NavLink className="dropdown-toggle" id="fgts_toggler">
            <i className="ni ni-chart-bar-32 text-primary" />
            Dados de Comissão
          </NavLink>
          <UncontrolledCollapse
            toggler="#fgts_toggler"
            className="primary mt-0"
          >
            {user && user.has_fgts && hasFgtsDataPermission.length > 0 && (
              <NavItem>
                <NavLink
                  to="/admin/fgts_commission"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeClassName="active"
                  className="p-1"
                >
                  <span style={{ marginLeft: 60 }}>FGTS</span>
                </NavLink>
              </NavItem>
            )}
            {user && user.has_cdc && hasCdcDataPermission.length > 0 && (
              <NavItem>
                <NavLink
                  to="/admin/cdc_commission"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeClassName="active"
                  className="p-1"
                >
                  <span style={{ marginLeft: 60 }}>CDC</span>
                </NavLink>
              </NavItem>
            )}
            {hasCardCommission.length > 0 && (
              <NavItem>
                <NavLink
                  to="/admin/commission"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeClassName="active"
                  className="p-1"
                >
                  <span style={{ marginLeft: 60 }}>Cartão</span>
                </NavLink>
              </NavItem>
            )}
          </UncontrolledCollapse>
        </NavItem>
      );
    } else {
      return false;
    }
  };

  const sendLinkWhats = () => {
    const phone = user.user_manager_phone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');
    const message = `Sou%20parceira%20NS%20da%20loja%20${user.store_name}%2C%20preciso%20de%20ajuda.`;
    window.open(
      `https://api.whatsapp.com/send?phone=55${phone}&text=${message}.`
    );
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav className="d-none">
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <i className="fas fa-filter"></i>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bem-vindo!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Perfil</span>
              </DropdownItem>
              <DropdownItem to="/admin/password/users" tag={Link}>
                <i className="fas fa-lock"></i>
                <span>Alterar Senha</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {user && user.support_whats && (
            <>
              {user && user.user_manager_name && (
                <div className="text-center mr-4">
                  <p>Dúvidas?</p>
                  <p>Chame nosso Ascom.</p>
                </div>
              )}
              {user && user.user_manager_phone && (
                <div className="text-center mr-4">
                  <p>{user.user_manager_phone}</p>
                </div>
              )}
              {user && user.user_manager_name && (
                <div className="text-center mr-4">
                  <Button color="success" onClick={sendLinkWhats}>
                    <i className="fab fa-whatsapp mr-2" />
                    Mensagem
                  </Button>
                </div>
              )}
            </>
          )}
          {/* Navigation */}
          <Nav navbar>
            {((user && user.has_fgts && user.has_uy3_create) ||
                (user && (user.kind == 'master' ||
                user.kind == 'operational' ||
                user.kind == 'commercial_manager_internal' ||
                user.kind == 'commercial_manager' ||
                user.kind == 'supervisor_commmercial_internal' ||
                user.kind == 'regional_manager')))  && (
              <UncontrolledDropdown
                setActiveFromChild
                style={{ marginLeft: 8 }}
              >
                <DropdownToggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ boxShadow: 'none', fontWeight: '300' }}
                >
                  <i
                    className={'fas fa-file-contract text-success'}
                    style={{ marginRight: 18 }}
                  />
                  Saque FGTS
                </DropdownToggle>

                <DropdownMenu>
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' &&
                    user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                    <DropdownItem href="/admin/fgts-customer-services/create-uy3">
                      +Nova Proposta FGTS 
                    </DropdownItem>
                  )}
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' && (
                      <DropdownItem href="/admin/fgts-customer-services">
                        Atendimentos FGTS
                      </DropdownItem>
                    )}
                  {user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                      <DropdownItem href="/admin/fgts-contracts-uy3">
                        Esteira FGTS
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {/* {((user && user.has_fgts && user.has_fgts_create) ||
                (user && (user.kind == 'master' ||
                user.kind == 'operational' ||
                user.kind == 'commercial_manager')))  && (
              <UncontrolledDropdown
                setActiveFromChild
                style={{ marginLeft: 8 }}
              >
                <DropdownToggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ boxShadow: 'none', fontWeight: '300' }}
                >
                  <i
                    className={'fas fa-file-contract text-success'}
                    style={{ marginRight: 18 }}
                  />
                  Saque FGTS
                </DropdownToggle>

                <DropdownMenu>
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' &&
                    user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                    <DropdownItem href="/admin/fgts-customer-services/create">
                      +Nova Proposta FGTS
                    </DropdownItem>
                  )}
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' && (
                      <DropdownItem href="/admin/fgts-customer-services">
                        Atendimentos FGTS
                      </DropdownItem>
                    )}
                  {user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                      <DropdownItem href="/admin/fgts-contracts">
                        Esteira FGTS
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )} */}
            {user &&
              ((user.has_cdc && user.has_cdc_create) ||
                user.kind == 'master' ||
                user.kind == 'operational' ||
                user.kind == 'regional_manager') && (
                <UncontrolledDropdown
                  setActiveFromChild
                  style={{ marginLeft: 8 }}
                >
                  <DropdownToggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ boxShadow: 'none', fontWeight: '300' }}
                  >
                    <i
                      className={'fas fa-file-contract text-success'}
                      style={{ marginRight: 18 }}
                    />
                    CDC FGTS
                  </DropdownToggle>

                  <DropdownMenu>
                    {user?.role_default === 'master' && (
                      <DropdownItem href="/admin/fgts-customer-services/create?isCdc=true">
                        +Nova Proposta CDC FGTS
                      </DropdownItem>
                    )}
                    {user?.role_default !== 'user_corban_financial' &&
                      user?.role_default !== 'user_store_financial' &&
                      user?.role_default !== 'user_seller_financial' &&
                      user?.role_default !== 'user_subest_financial' && (
                        <DropdownItem href="/admin/fgts-customer-services?isCdc=true">
                          Atendimentos CDC FGTS
                        </DropdownItem>
                      )}
                    {user?.role_default !== 'user_corban_register' &&
                      user?.role_default !== 'user_store_register' &&
                      user?.role_default !== 'user_seller_register' &&
                      user?.role_default !== 'user_subest_register' && (
                        <DropdownItem href="/admin/fgts-contracts?isCdc=true">
                          Esteira CDC FGTS
                        </DropdownItem>
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

            {((user && user.has_credit_create) ||
                (user && (user.kind == 'master' ||
                user.kind == 'operational' ||
                user.kind == 'commercial_manager_internal' ||
                user.kind == 'commercial_manager' ||
                user.kind == 'supervisor_commmercial_internal' ||
                user.kind == 'regional_manager'))) && (
              <UncontrolledDropdown
                setActiveFromChild
                style={{ marginLeft: 8 }}
              >
                <DropdownToggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ boxShadow: 'none', fontWeight: '300' }}
                >
                  <i
                    className={'fas fa-file-contract text-success'}
                    style={{ marginRight: 18 }}
                  />
                  Emprestimo Cartão
                </DropdownToggle>

                <DropdownMenu>
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' &&
                    user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                      <DropdownItem href="/admin/customer-services/create">
                        +Nova Proposta Cartão
                      </DropdownItem>
                    )}
                  {user?.role_default !== 'user_corban_financial' &&
                    user?.role_default !== 'user_store_financial' &&
                    user?.role_default !== 'user_seller_financial' &&
                    user?.role_default !== 'user_subest_financial' && (
                      <DropdownItem href="/admin/customer-services">
                        Atendimentos Cartão
                      </DropdownItem>
                    )}
                  {user?.role_default !== 'user_corban_register' &&
                    user?.role_default !== 'user_store_register' &&
                    user?.role_default !== 'user_seller_register' &&
                    user?.role_default !== 'user_subest_register' && (
                      <DropdownItem href="/admin/contracts">
                        Esteira Cartão
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {renderDataCommissionLink(routes)}
            {createLinks(routes)}
          </Nav>
          {/* Divider */}
          <hr className="my-3" />
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
