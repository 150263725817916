import React from 'react';
import { Table, Input, Label } from 'reactstrap';

const TableSimulationFgts = ({
  simulationDataFgts,
  tableName,
  selectedTable,
  productId,
  selectTableFgts,
  getCommissionSeller
}) => {
  return (
    <>
      <h3 className="mb-4 d-flex">{tableName}</h3>
      <Table className="align-items-center justify-content-center table-flush">
        <thead className="">
          <tr>
            <th scope="col">Valor Liberado</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  checked={selectedTable.product_id === productId}
                  onChange={() => {
                    console.log('SELECTED', simulationDataFgts);
                    selectTableFgts(simulationDataFgts);
                    getCommissionSeller(
                      simulationDataFgts.response.liquidValue/100,
                      simulationDataFgts.product_id,
                    );
                  }}
                />
                {(simulationDataFgts.response.liquidValue / 100).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Label>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TableSimulationFgts;
