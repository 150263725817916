import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import Pagination from 'react-responsive-pagination';
import { LoadingContext } from '../../context/loading/LoadingContext';
import './style.css';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';
import InputMask from 'react-input-mask';

const LIMIT = 10;

const Seller = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [sellers, setSellers] = useState([]);
  const [stores, setStores] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const [cpf, setCpf] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStore, setFilterStore] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
        return diff;
    }

    return -1 * diff;
  }

  const exportStoreRef = useRef(exportStoreRef)
  const [exportStoreData, setExportStoreData] = useState([])
  const [storeReportDisabled, setStoreReportDisabled] = useState(false)


  async function getSellers() {
    showLoader();

    try {
      const response = await api.get(
        `sellers?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSellers(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/sellers',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Vendedores.');
    }
  }

  async function getStores() {

    try {
      const response = await api.get(
        `searchs/stores`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if(response.data){
        setStores(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }


    } catch (error) {
      toastError('Ops, não foi possível lista os Lojas.');
    }
  }

  async function export_store_report() {
    setStoreReportDisabled(true)
    try {
      const response = await api.get(
        `sellers/seller_export?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
        console.log(response.data)
      setExportStoreData(response.data)
      exportStoreRef?.current?.link.click();
    } catch(e) {

    }
    setStoreReportDisabled(false)
  }


  useEffect(() => {
    if(user && user.roles[0] && (["master", "operational", "regional_manager", "commercial_manager", "supervisor_commmercial_internal", "commercial_manager_internal", "superintendent_commercial", "intern_consultant"].includes(user.roles[0].name))){
      getStores()
    }
  }, [user])
  useEffect(() => {
    getSellers();
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handleOnClick() {

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `sellers?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setSellers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`sellers?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function disabledSeller(seller_id) {
    try {
      const { data } = await api.put(`sellers/${seller_id}`, {seller: {status: "disabled"}}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSellers()
    } catch (e) {
      toastError('Ops, houve um erro ao atualizar o vendedor');
    }

  }

  async function enableSeller(seller_id) {
    try {
      const { data } = await api.put(`sellers/${seller_id}`, {seller: {status: "active"}}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSellers()
    } catch (e) {
      toastError('Ops, houve um erro ao atualizar o vendedor');
    }

  }

  async function removeSeller(seller_id) {
    try {
      const { data } = await api.delete(`sellers/${seller_id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSellers()
    } catch (e) {
      toastError(e.response.data.error);
      console.error('Ops, houve um erro ao excluir o vendedor');
    }

  }


  async function onChange(e) {
    setCpf(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item["entity"].cpf_cnpj} - {item["entity"].name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item["entity"].cpf_cnpj}`;
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `sellers?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setSellers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    }

    let filterPath = '';

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterStore) {
      filterPath += `&q[store_id_eq]=${filterStore}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    if (filterState) {
      filterPath += `&q[entity_address_state_eq]=${filterState}`;
    }

    if (filterCommission) {
      filterPath += `&q[entity_has_commission_eq]=${filterCommission}`;
    }

    if (filterEmail) {
      filterPath += `&q[users_email_cont]=${filterEmail}`;
    }

    hideLoader();
    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterState('')
    setFilterCommission('')
    setFilterEmail('')
    setSearchFilter('')
    handleToggleFilter()
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>

            <div className="col">
              {
                /**
                 * Existem corbans que não desejam que seus usuarios lojas possam cadastrar vendedor
                 * dentro do objeto corban no banco tem um atributo que se chama: corban_stores_can_register_seller
                 * Este atributo está mapeado dentro do serializer de user, assim podemos ter algo como:
                 * user.corban_stores_can_register_seller
                 * Sempre que quisermos que as lojas de um corban não sejam capazes de criar vendedores, devemos setar
                 * dentro deste corban o atributo corban_stores_can_register_seller para false.
                 * Todos os corbans tem o atributo corban_stores_can_register_seller true por padrão
                 */
                user && user.corban_stores_can_register_seller && (
                  <div className="text-right">
                    {user && user.can_register_seller ? (
                      <Link
                        className="btn btn-success mb-3 mt-2"
                        to="/admin/sellers/create"
                      >
                        <i className="fas fa-plus" /> Cadastrar novo vendedor
                      </Link>
                    ) : (
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          // 'regional_manager',
                          'master_store',
                          'master_corban',
                          'user_store',
                          'user_corban_register',
                          'user_store_register',
                          'user_subest_register',
                        ]}
                      >
                        <Link
                          className="btn btn-success mb-3 mt-2"
                          to="/admin/sellers/create"
                        >
                          <i className="fas fa-plus" /> Cadastrar novo vendedor
                        </Link>
                      </VisibilityByUserRoles>
                    )}
                  </div>
                )
              }
              
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Vendedores</h3>
                      {/* <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cpf}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder="Buscar pelo CPF"
                      /> */}
                    </Col>
                    <Col>
                      {
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            {
                              <>
                                <button
                                  disabled={storeReportDisabled}
                                  className="btn btn-primary"
                                  onClick={export_store_report}
                                >
                                  Exportar Vendedores
                                </button>
                                <CSVLink
                                  data={exportStoreData}
                                  filename={'seller_report.csv'}
                                  separator={';'}
                                  ref={exportStoreRef}
                                ></CSVLink>
                              </>
                            }
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CPF</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Telefone</th>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Corban</th>
                        <th scope="col">Subestabelecimento</th>
                        <th scope="col">Loja</th>
                      </VisibilityByUserRoles>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sellers.map((seller) => (
                      <tr key={seller.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {seller.entity.cpf_cnpj.replace(
                                  /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                                  '$1.$2.$3-$4'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{seller.entity.name}</td>
                        <td>{seller.mobile}</td>
                        <VisibilityByUserRoles
                          roles={['master', 'operational', 'regional_manager']}
                        >
                          <td>{seller.corban_name}</td>
                          <td>{seller.sub_name}</td>
                          <td>{seller.store_name}</td>
                        </VisibilityByUserRoles>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`${
                                seller.status === 'Ativo'
                                  ? 'bg-success'
                                  : seller.status === 'Inativo'
                                  ? 'bg-danger'
                                  : ''
                              }`}
                            />
                            {seller.status}
                          </Badge>
                        </td>
                        {/* <td>
                          <Link
                            to={`/admin/contracts?seller_id=${seller.id}`}
                            className="btn btn-warning btn-block"
                          >
                            Contratos
                          </Link>
                        </td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {seller.status_original == 'disabled' && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                    'master_corban',
                                    'master_subest',
                                    'master_store',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() => enableSeller(seller.id)}
                                  >
                                    <span>
                                      <i className="fas fa-check" /> Ativar
                                      Vendedor
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              {seller.status_original == 'active' && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                    'master_corban',
                                    'master_subest',
                                    'master_store',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() => disabledSeller(seller.id)}
                                  >
                                    <span>
                                      <i className="fas fa-times" /> Desativar
                                      Vendedor
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              <Link
                                to={`/admin/sellers/${seller.id}/detail`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-info-circle" /> Detalhes
                                  do Vendedor
                                </span>
                              </Link>
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <DropdownItem
                                  onClick={() => removeSeller(seller.id)}
                                >
                                  <span>
                                    <i className="fas fa-times" /> Excluir
                                    Vendedor
                                  </span>
                                </DropdownItem>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <Link
                                  to={`/admin/sellers/${seller.id}/logs`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-file" /> Log de
                                    Alterações
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                  'master_corban',
                                  'master_subest',
                                  'master_store',
                                  'user_corban_register',
                                  'user_subest_register',
                                  'user_store_register',
                                ]}
                              >
                                <Link
                                  to={`/admin/sellers/${seller.id}`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-edit" /> Editar
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'commercial_manager',
                    'supervisor_commmercial_internal',
                    'commercial_manager_internal',
                    'superintendent_commercial',
                    'intern_consultant',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="cpf">
                      CPF
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      className="form-control"
                      id="cpf"
                      name="cpf"
                      placeholder="Digite o CPF"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleCustomSelect"
                    >
                      Loja
                    </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="customSelect"
                      onChange={(e) => setFilterStore(e.target.value)}
                      value={filterStore}
                    >
                      <option value="">Todos</option>
                      {stores &&
                        stores.map((store) => (
                          <option value={store.id}>{store.name}</option>
                        ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Digite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="state">
                    Estado
                  </Label>
                  <CustomInput
                    type="select"
                    id="state"
                    name="state"
                    onChange={(e) => setFilterState(e.target.value)}
                    value={filterState}
                  >
                    {States.map((state) => (
                      <option value={state.value}>{state.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="commission"
                  >
                    Comissão Ativa
                  </Label>
                  <CustomInput
                    type="select"
                    id="commission"
                    name="commission"
                    onChange={(e) => setFilterCommission(e.target.value)}
                    value={filterCommission}
                  >
                    <option value="">Selecione</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </CustomInput>
                </FormGroup> */}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Seller;
